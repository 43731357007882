import React from "react";
import {KeyFrameEvent, Settable} from "./utils";


interface Props {
    keyFrameEvent: KeyFrameEvent | null,
    handleDeletion: () => void,
    isUsingDMMode: boolean,
    deletionActive: boolean,
    handleDeselection: () => void
}

export default function SelectedKeyFrameEvent(props: Props) {
    const {
        keyFrameEvent,
        handleDeletion,
        handleDeselection,
        deletionActive
    } = props;
    if (keyFrameEvent === null) {
        return null;
    }
    function SettableTextField<T>(field: string, formLabel: string) {
        // @ts-ignore
        const keyFrameEventField: Settable<T, string> = keyFrameEvent[field];
        const key = String(keyFrameEvent!.eventID) + field;
        const handleChange = ({target: {value}} : {target: {value: string}}) => {
            keyFrameEventField.set(value)
        }
        return (
            <div className="textKeyFrameEventField" key={key}>
                <label htmlFor={key}>
                    {formLabel}
                    <input
                      type="text"
                      readOnly={false}
                      id={key}
                      name={key}
                      onChange={handleChange}
                      value={keyFrameEventField.unParsedValue}
                    />
                </label>
            </div>
        )
    }
    function intFieldComponent(field: string, formLabel: string) {
        // TODO be smarter about this
        return SettableTextField<string>(field, formLabel);
    }
    function stringFieldComponent(field: string, formLabel: string) {
        return SettableTextField<string>(field, formLabel)
    }

    const deleter = (!deletionActive) ? (null) : (<div><button onClick={handleDeletion}>Delete selected event</button></div>);

    // render
    return (
        <div className="selectedKeyFrameEvent">
            <div className="selectedKeyFrameEventHeading">Event</div>
            {stringFieldComponent("name", "Name")}
            {intFieldComponent("endSequence", "Ends on sequence")}
            {intFieldComponent("endAtStartOfShot", "Ends at start of shot")}
            <button onClick={handleDeselection}>Close</button>
            {deleter}
        </div>
    )
}