import {SettableCharacter, DiceRoll, diceRollMaxLength, CharacterType, formatRolls, formatNumNamed} from "./utils";
import React, {useEffect, useState} from "react";
import {roll1d6, rollDice, sum} from "./mathUtils";
import {UpDownButton} from "./UpDownButton";
import {TooltipSpan} from "./Tooltip";

interface RollProps {
    diceRoll: DiceRoll
    nFortuneDice: number,
    nDiceResolved: number,
    skillValue: number,
    isUsingDMMode: boolean,
    impairment: number
}

function RollComponent(props: RollProps) {
    const {
        diceRoll,
        nFortuneDice,
        nDiceResolved,
        skillValue,
        isUsingDMMode,
        impairment
    } = props;
    const fortuneDice = diceRoll.rawFortunes.slice(0, nFortuneDice);
    const numBoxcars = Math.max(0, Math.min(diceRoll.plusses.length, diceRoll.minuses.length) - 1);
    const plusSum = sum(diceRoll.plusses.slice(0, nDiceResolved)) - numBoxcars * 6;
    const minusSum = sum(diceRoll.minuses.slice(0, nDiceResolved)) - numBoxcars * 6;
    const fortuneSum = sum(fortuneDice);

    const swerve = plusSum + fortuneSum - minusSum;

    const plusDiceComponent = formatRolls(diceRoll.plusses, numBoxcars, "plusDie", nDiceResolved);
    const minusDiceComponent = formatRolls(diceRoll.minuses, numBoxcars, "minusDie", nDiceResolved);
    const fortuneDiceComponent = formatRolls(fortuneDice, 0, "fortuneDie", nDiceResolved > 0 ? Number.POSITIVE_INFINITY : 0);
    const optionalFortuneCalc = " + " + formatNumNamed(fortuneSum, "fortune");
    // const plusSumComponent = <TooltipSpan text={plusSum} tooltipText={plusDiceComponent}/>
    // const minusSumComponent = <TooltipSpan text={minusSum} tooltipText={minusDiceComponent}/>
    // const fortuneSumComponent = <TooltipSpan text={fortuneSum} tooltipText={fortuneDiceComponent}/>
    const swerveCalc = (
        formatNumNamed(plusSum, "plus") +
        (nFortuneDice > 0 ? optionalFortuneCalc : "") +
        " - " + formatNumNamed(minusSum, "minus")
    )
    const swerveComponent = <TooltipSpan text={"Swerve total: " + swerve} tooltipText={swerveCalc}/>

    const optionalImpairmentCalc = " - " + formatNumNamed(impairment, "impairment");
    const actionResultCalc = formatNumNamed(skillValue, "AV") +
        (impairment > 0 ? optionalImpairmentCalc : "") +
        " + " + formatNumNamed(swerve, "swerve");
    const actionResult = swerve + skillValue;
    const actionResultComponent = <TooltipSpan text={"Action result: " + actionResult} tooltipText={actionResultCalc}/>


    const diceRollComponent = (
        <div className="skillDice">
            <span className="plusDice">{plusDiceComponent}</span>
            <span className="fortuneDice">{fortuneDiceComponent}</span>
            <span className="minusDice">{minusDiceComponent}</span>
        </div>
    )
    return (
        <div className="skillDiceRoll">
            {diceRollComponent}
            <div className="skillDiceRollResults">
                {nDiceResolved > 0 ? swerveComponent : null}
                {nDiceResolved > 0 && isFinite(skillValue) ? actionResultComponent : null}
            </div>
        </div>
    )
}

interface Props {
    character: SettableCharacter | null,
    isUsingDMMode: boolean
}

export default function SkillRoller(props: Props) {
    const {character, isUsingDMMode} = props;
    const [diceRolls, setDiceRolls] = useState<DiceRoll[]>([]);
    const [nFortuneDice, setNFortuneDice]  = useState<number>(0);
    const [nDiceResolved, setNDiceResolved]  = useState<number>(0);
    const [isRolling, setIsRolling] = useState<boolean>(false);
    const [skillValue, setSkillValue] = useState<string>("7");

    useEffect(() => {
        if (isRolling) {
            const rollTimeForFirst = Math.random()*900+900; // 900 min, 1800 max
            const rollTimeForNext = Math.random()*1300+900; // 900 min, 2200 max
            const rollTime = nDiceResolved === 0 ? rollTimeForFirst : rollTimeForNext;
            const timeout = setTimeout(() => {
                const diceNeedingResolving = Math.max(...diceRolls.map(diceRollMaxLength), nFortuneDice);
                if (nDiceResolved + 1 >= diceNeedingResolving) {
                    setIsRolling(false);
                    setNDiceResolved(Number.POSITIVE_INFINITY);
                } else {
                    setNDiceResolved(nDiceResolved+1)
                }
            }, rollTime)
            return () => clearTimeout(timeout)
        }
    }, [isRolling, nDiceResolved, diceRolls, nFortuneDice])
    let nDiceRolls = 1;
    let maxFortuneSpendable = Number.POSITIVE_INFINITY;
    if (character !== null && character.characterType.value === CharacterType.MOOK) {
        nDiceRolls = character.howMany.value
        maxFortuneSpendable = character.fortune.value;
    }
    const handleRoll = () => {
        const tmpDiceRolls: DiceRoll[] = [];
        for (let i = 0; i < nDiceRolls; i++) {
            const [plusses, minuses] = rollDice();
            const fortunes = Array.from({length: nFortuneDice+100}, () => roll1d6())
            tmpDiceRolls.push({
                plusses: plusses,
                minuses: minuses,
                rawFortunes: fortunes
            })
        }
        setDiceRolls(tmpDiceRolls);
        setNDiceResolved(0);
        setIsRolling(true);
    }

    const impairment = character === null ? 0 : character.impairment;
    const impairmentString = impairment > 0 ? "🤕" : ""

    const fortuneComponent = <UpDownButton label="Skill fortune dice" value={nFortuneDice}
                                           setValue={setNFortuneDice}
                                           minValue={0} maxValue={maxFortuneSpendable}/>
    const rollComponents = []
    for (const [index, roll] of diceRolls.entries()) {
        rollComponents.push(
            <RollComponent
                diceRoll={roll} isUsingDMMode={isUsingDMMode}
                nFortuneDice={nFortuneDice} nDiceResolved={nDiceResolved}
                skillValue={parseInt(skillValue)}
                impairment={impairment}
                key={index}
            />
        )
    }
    const handleChange = ({target: {value}} : {target: {value: string}}) => {
            setSkillValue(value)
        }
    const skillParses = isFinite(parseInt(skillValue))
    const skillComponent = (
        <div className="textCharacterField">
            <label htmlFor="skillValue">
                {"Skill AV: "}
                <input className={skillParses ? "" : "invalidTextCharacterField"}
                  type="text"
                  id={skillValue}
                  name={skillValue}
                  onChange={handleChange}
                  value={skillValue}
                />
            </label>
        </div>
    )
    return <div className="skillRoller">
        <div className="skillRollerHeader">
            Skills
        </div>
        {skillComponent}
        {fortuneComponent}
        <button onClick={handleRoll}>Roll skill</button>
        {impairmentString}
        {rollComponents}
    </div>
}