import React from "react";
import MinimalCharacterSelector from "./MinimalCharacterSelector";
import {
    CharacterID,
    CharacterListType,
    CharacterType,
    characterTypeToPriority,
    insertNewCharacter,
    SettableCharacter,
    YCharacterList
} from "./utils";

interface Props {
    yArray?: YCharacterList,
    characters: Map<CharacterID, SettableCharacter>,
    characterListType: CharacterListType,
    characterIDsToCountSelected: Map<CharacterID, number>,
    setCharacterIDsToCountSelected?:  React.Dispatch<React.SetStateAction<Map<CharacterID, number>>>,
    readOnly: boolean,
    isUsingDMMode: boolean,
    maxSelectable: number,
    isTarget: boolean,
    isTargetSection: boolean
    potentialAttacker?: SettableCharacter | null
}

export default function CharacterList(props: Props) {
    const {yArray, characters, characterListType,
        characterIDsToCountSelected, setCharacterIDsToCountSelected,
        readOnly, isUsingDMMode, maxSelectable, isTargetSection,
        potentialAttacker
    } = props;

    const newCharacterType =characterListType === CharacterListType.PC ? CharacterType.PC : CharacterType.FEATURED_FOE;
    const handleNewCharacter = () => {
        if (yArray !== undefined) {
            const newCharacterID = insertNewCharacter(yArray, newCharacterType);
            if (setCharacterIDsToCountSelected !== undefined) {
                setCharacterIDsToCountSelected(new Map().set(newCharacterID, 1));
            }
        }
    };
    const characterComponents : JSX.Element[] = [];
    const sortedCharacters = Array.from(characters.values()).sort((a, b) => (characterTypeToPriority(b.characterType.value) - characterTypeToPriority(a.characterType.value)))
    sortedCharacters.forEach(
        (character: SettableCharacter) => {
            const thisCharacterID = character.characterID;
            const maybeThisIDAndCount = characterIDsToCountSelected.get(thisCharacterID)
            const isSelected = (maybeThisIDAndCount !== 0) && (maybeThisIDAndCount !== undefined);
            const isTheSoleSelected = isSelected && (characterIDsToCountSelected.size === 1);
            const thisCountSelected = (maybeThisIDAndCount === undefined) ? 0 : maybeThisIDAndCount;
            const isUnselectedInTargetMode = !isSelected && isTargetSection;
            const component = (<MinimalCharacterSelector
                key={thisCharacterID}
                character={character}
                setSelectedCharacterIDsAndCounts={setCharacterIDsToCountSelected}
                isSelected={isSelected}
                isTheSoleSelected={isTheSoleSelected}
                countSelected={thisCountSelected}
                isUsingDMMode={isUsingDMMode}
                maxSelectable={maxSelectable}
                selectedCharacterIDsAndCounts={characterIDsToCountSelected}
                isTargetSection={isTargetSection}
                potentialAttacker={potentialAttacker}
            />)
            const hiddenFromPCs = !isUsingDMMode && !character.visible.value
            if (!isUnselectedInTargetMode && !hiddenFromPCs) {
                characterComponents.push(component);
            }
        })
    const buttons = (readOnly || (maxSelectable === 0)) ? null : (<>
            <button onClick={handleNewCharacter}>New {characterListType}</button>
        </>
    )
    const selectedExtras = <>
        {maxSelectable > 1 ? <th className="noLeftBorderTH">Target</th> : null}
    </>
    const dmExtras = (isUsingDMMode && (maxSelectable > 0)) ? [(<th key="dead" className="leftBorderTH">☠️</th>), (<th key="visible" className="leftBorderTH">👻</th>)] : [];
    if (characterComponents.length === 0) {
        return buttons;
    }
    function hideForPCs(element: JSX.Element): JSX.Element | null {
        if (characterListType !== CharacterListType.PC && !isUsingDMMode) {
            return null
        }
        return element
    }
    return (
        <div className="characterList">
            <table style={{border: 1}}>
                <thead>
                    <tr>
                        {selectedExtras}
                        <th>Name</th>
                        {hideForPCs(<th className="leftBorderTH">🕶</th>)}
                        {hideForPCs(<th className="leftBorderTH">🗡</th>)}
                        <th className="leftBorderTH">🛡</th>
                        {hideForPCs(<th className="leftBorderTH">💪</th>)}
                        {hideForPCs(<th className="leftBorderTH">🏃</th>)}
                        <th className="leftBorderTH">🩸</th>
                        <th className="leftBorderTH">🤕</th>
                        {dmExtras}
                    </tr>
                </thead>
                <tbody>
                    {characterComponents}
                </tbody>
            </table>
            {buttons}
        </div>
    )
}