
export function newRandomID() {
    // first 32 bit float that this would screw up is about 16M? if so 10M is fine
    return Math.round(Math.random() * 1e7);
}

export function roll1d6(): number {
    return Math.ceil(Math.random() * 6);
}

function roll1d6Exploding(previousDice: number[]): number[] {
    const dieRoll = roll1d6();
    previousDice.push(dieRoll);
    if (dieRoll === 6) {
        return roll1d6Exploding(previousDice);
    }
    return previousDice;
}

export function rollDice(): [number[], number[]] {
    const plusses = roll1d6Exploding([]);
    const minuses = roll1d6Exploding([]);
    return [plusses, minuses]
}

export function sum(arr: number[]): number {
    return arr.reduce((x, acc) => x + acc, 0);
}

type IntDistribution = Map<bigint, number>;
function newIntDistribution(): IntDistribution {return new Map<bigint, number>()}

function calcApprox1d6ExplodingDistribution(): IntDistribution {
    const val2Prob = newIntDistribution();
    for (let numExplodedAlready = 0; numExplodedAlready < 20; numExplodedAlready++) {
        const pExplodedToHere = 1/Math.pow(6, numExplodedAlready);
        const pStoppedExplodingHere = 5/6;
        const pExactlyThisManyExploded = pExplodedToHere * pStoppedExplodingHere;
        for (let nonExplodingValue = 1; nonExplodingValue < 6; nonExplodingValue++) {
            const pResultGivenStoppedExplodingHere = 1/5;
            const result = 6 * numExplodedAlready + nonExplodingValue;
            const pResult = pResultGivenStoppedExplodingHere * pExactlyThisManyExploded;
            val2Prob.set(BigInt(result), pResult);
        }
    }
    return val2Prob;
}

function negatedDistribution(d: IntDistribution): IntDistribution {
    const n2p = newIntDistribution()
    d.forEach((p, n) => n2p.set(-n, p));
    return n2p
}

function calcSumOfTwoDistributions(d1: IntDistribution, d2: IntDistribution) : IntDistribution {
    const n2p = newIntDistribution();
    for (const [n1, p1] of d1) {
        for (const [n2, p2] of d2) {
            const sum = n1 + n2;
            let pSum = p1*p2;
            const priorPSum = n2p.get(sum);
            if (priorPSum !== undefined) {
                pSum += priorPSum
            }
            n2p.set(sum, pSum);
        }
    }
    return n2p;
}

function calcSumOfNDistributions(ds: IntDistribution[]): IntDistribution {
    // quadratic in number of distributions and quadratic-ish in support of largest distributions
    let n2p = newIntDistribution();
    n2p.set(BigInt(0), 1);
    for (const d of ds) {
        n2p = calcSumOfTwoDistributions(n2p, d);
    }
    return n2p
}

const oneD6Distribution = newIntDistribution();
for (let i = BigInt(1); i <= 6; i++) {
    oneD6Distribution.set(i, 1/6);
}


function calcSomeFortuneResults(upToHowMany: number): Map<bigint, IntDistribution> {
    const fortuneLookup = new Map<bigint, IntDistribution>();
    let n2p = newIntDistribution()
    n2p.set(BigInt(0), 1);
    fortuneLookup.set(BigInt(0), n2p);
    for (let i = BigInt(1); i <= upToHowMany; i++) {
        n2p = calcSumOfTwoDistributions(n2p, oneD6Distribution);
        fortuneLookup.set(i, n2p);
    }
    return fortuneLookup
}
const fortuneLookup = calcSomeFortuneResults(100);
function calcFortuneResults(howMany: number): IntDistribution {
    const maybeFortuneDistribution = fortuneLookup.get(BigInt(howMany));
    if (maybeFortuneDistribution !== undefined) {
        return maybeFortuneDistribution;
    }
    return calcSumOfNDistributions(Array(howMany).fill(oneD6Distribution));
}

const approx1d6ExplodingDistribution = calcApprox1d6ExplodingDistribution();
const approxSwerveDistributionWithoutFortune = calcSumOfTwoDistributions(
    approx1d6ExplodingDistribution,
    negatedDistribution(approx1d6ExplodingDistribution)
);

export function calcApproxSwerveDistribution(nFortuneDice: number): IntDistribution {
    const fortuneDist: IntDistribution = calcFortuneResults(nFortuneDice);
    return calcSumOfTwoDistributions(approxSwerveDistributionWithoutFortune, fortuneDist);
}

function distMean(d: IntDistribution): number {
    let avg = 0;
    for (const [x, p] of d) {
        avg += Number(x)*p;
    }
    return avg;
}

function distMass(d: IntDistribution): number {
    let mass = 0;
    for (const p of d.values()) {
        mass += p;
    }
    return mass
}

export function calcExpectedDamages(nFortuneDice: number, actionValue:number, difficulty: number,
                             weaponDamage: number, toughnesses: number[]): [number, number[]] | null {
    if (!isFinite(difficulty) || !isFinite(actionValue)) {
        return null;
    }
    const swerveDistribution = calcApproxSwerveDistribution(nFortuneDice);
    const outcomeDistribution: IntDistribution = new Map<bigint, number>();
    const actionMinusDifficulty = BigInt(actionValue-difficulty);
    let pHit = 0;
    for (const [swerve, p] of swerveDistribution) {
        const outcome = actionMinusDifficulty+swerve;
        if (outcome >= 0) {
            outcomeDistribution.set(outcome, p);
            pHit += p
        }
    }
    const avgWounds = toughnesses.map(_ => 0);
    for (const [outcome, p] of outcomeDistribution) {
        const damage = weaponDamage + Number(outcome);
        for (let i = 0; i < toughnesses.length; i++) {
            const toughness = toughnesses[i]
            if (isFinite(toughness)) {
                const woundsDealt = damage - toughness;
                if (woundsDealt > 0) {
                    avgWounds[i] = avgWounds[i] + p*woundsDealt;
                }
            }
        }
    }
    return [pHit, avgWounds];
}

export function getRandomSubarray<T>(arr: T[], size: number): T[] {
    const actualSize = Math.min(arr.length, size);
    var shuffled = arr.slice(0), i = arr.length, min = i - actualSize, temp, index;
    while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(min);
}