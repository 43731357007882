import * as Y from "yjs";
import React from "react";
import {newRandomID} from "./mathUtils";

export enum CharacterListType {
    PC="PC",
    ENEMY="Enemy",
    TARGET="Target"
}

export enum CharacterType {
    PLACEHOLDER="PLACEHOLDER",
    PC="PC",
    MOOK="MOOK",
    FEATURED_FOE="FEATURED_FOE",
    BOSS="BOSS",
    UBERBOSS="UBERBOSS"
}

export enum FortuneType {
    CHI="Chi",
    MAGIC="Magic",
    FORTUNE="Fortune"
}

export enum AttackType {
    ATTACK="Attack",
    MARTIAL_ARTS="Martial arts",
    GUNS="Guns"
}

export function characterTypeToPriority(characterType: CharacterType) {
    switch (characterType) {
        case CharacterType.UBERBOSS:
            return 1
        case CharacterType.BOSS:
            return 2
        case CharacterType.FEATURED_FOE:
            return 3
        case CharacterType.MOOK:
            return 4
        case CharacterType.PC:
            return 5
        default:
            return 6


    }
}

export function calculateImpairment(woundPoints: number): number {
    if (woundPoints >= 30) {
        return 2;
    }
    if (woundPoints >= 25) {
        return 1;
    }
    return 0;
}

export function newYMapWithFieldValue<T>(value: T) {
    const yMap = new Y.Map();
    yMap.set("fieldValue", value);
    yMap.set("focusers", new Y.Array())
    return yMap;
}

export function setIfNotHas<T>(character: YCharacter, field: string, defaultValue: T) {
    if (!character.has(field)) {
        character.set(field, newYMapWithFieldValue<T>(defaultValue))
    }
}

function getFieldValueIfHas<T>(yCharacter: YCharacter, field:string, defaultValue: T) {
    if (yCharacter.has(field)) {
        return yCharacter.get(field).get("fieldValue")
    }
    const newYField = newYMapWithFieldValue(defaultValue);
    yCharacter.set(field, newYField);
    return newYField.get("fieldValue")
}

export function getValueIfHas<T>(yMap: Y.Map<any>, field:string, defaultValue: T) {
    // yMap is YCharacter or YKeyFrameEvent
    if (yMap.has(field)) {
        return yMap.get(field)
    }
    yMap.set(field, defaultValue);
    return defaultValue;
}


export function insertNewCharacter(yArray: YCharacterList, characterType: CharacterType): CharacterID {
        console.log("new ", characterType)
        const newCharacter: YCharacter = new Y.Map<any>();
        const characterID = newRandomID();
        newCharacter.set("characterID", characterID);
        newCharacter.set("characterType", characterType);
        newCharacter.set("howMany", 1);
        newCharacter.set("marksOfDeath", 0);
        newCharacter.set("name", newYMapWithFieldValue("Unnamed"));
        newCharacter.set("nickname", newYMapWithFieldValue("Unnamed"));
        newCharacter.set("actionValue", newYMapWithFieldValue("0"));
        newCharacter.set("attackType", AttackType.ATTACK);
        newCharacter.set("weaponDamage", newYMapWithFieldValue("0"));
        newCharacter.set("defense", newYMapWithFieldValue("0"));
        newCharacter.set("toughness", newYMapWithFieldValue("0"));
        newCharacter.set("woundPoints", newYMapWithFieldValue("0"));
        newCharacter.set("fortune", newYMapWithFieldValue(0));
        newCharacter.set("fortuneType", FortuneType.FORTUNE);
        newCharacter.set("speed", newYMapWithFieldValue("0"));
        newCharacter.set("defenseBonus", newYMapWithFieldValue(0));
        newCharacter.set("toughnessBonus", newYMapWithFieldValue(0));
        newCharacter.set("extraImpairment", newYMapWithFieldValue(0));
        newCharacter.set("visible", newYMapWithFieldValue(true));
        newCharacter.set("dead", newYMapWithFieldValue(false));
        newCharacter.set("nextShot", "-1");
        newCharacter.set("nextShotSetAt", -1);
        yArray.insert(0, [newCharacter]);
        return characterID;
    }

export type YCharacter = Y.Map<any>;
export type YCharacterList = Y.Array<YCharacter>

export interface Settable<T, V> {
    value: T,
    unParsedValue: V,
    set: (_: V) => void,
    parses: boolean
}

export interface SettableCharacter {
    characterID: number,
    characterType: Settable<CharacterType, CharacterType>,
    howMany: Settable<number, number>,
    marksOfDeath: Settable<number, number>,
    name: Settable<string, string>,
    nickname: Settable<string, string>,
    actionValue: Settable<number, string>,
    attackType: Settable<AttackType, AttackType>,
    weaponDamage: Settable<number, string>,
    defense: Settable<number, string>,
    toughness: Settable<number, string>,
    woundPoints: Settable<number, string>,
    fortune: Settable<number, number>,
    fortuneType: Settable<FortuneType, FortuneType>,
    speed: Settable<number, string>,
    defenseBonus: Settable<number, number>,
    toughnessBonus: Settable<number, number>,
    extraImpairment: Settable<number, number>,
    visible: Settable<boolean, boolean>,
    dead: Settable<boolean, boolean>,
    nextShot: Settable<number, string>
    nextShotSetAt: Settable<number, number>
    impairment: number,
    totalDefense: number,
    totalToughness: number,
    totalAV: number
}

function getSettableDirectValueIfHas<T, V>(
    yMap: YCharacter, field:string,
    defaultValue: V, parser: (_:V) => T,
    parses: (_: V) => boolean): Settable<T, V> {
    if (yMap.has(field)) {
        const unparsedValue = yMap.get(field)
        return {
            value: parser(unparsedValue),
            unParsedValue: unparsedValue,
            set: (newValue) => yMap.set(field, newValue),
            parses: parses(unparsedValue)
        }
    }
    yMap.set(field, defaultValue);
    return {
        value: parser(defaultValue),
        unParsedValue: defaultValue,
        set: (newValue) => yMap.set(field, newValue),
        parses: parses(defaultValue)
    };
}

function getSettableFieldValueIfHas<T, V>(
    yCharacter: YCharacter, field:string,
    defaultValue: V, parser: (_:V) => T,
    parses: (_: V) => boolean
    ): Settable<T, V> {
    if (yCharacter.has(field)) {
        const unparsedField = yCharacter.get(field)
        const unparsedValue = unparsedField.get("fieldValue")
        return {
            value: parser(unparsedValue),
            unParsedValue: unparsedValue,
            set: (newValue) => yCharacter.get(field).set("fieldValue", newValue),
            parses: parses(unparsedValue)
        }
    }
    yCharacter.set(field, newYMapWithFieldValue(defaultValue));
    return {
        value: parser(defaultValue),
        unParsedValue: defaultValue,
        set: (newValue) => yCharacter.set(field, newValue),
        parses: parses(defaultValue)
    };
}


function idParse<T>(x: T): T {return x}
function parsesTrue<T>(x: T) {return true}
function isNotEmpty(x: string) {return x.length > 0}
function parsesAsInt(x: string) {return isFinite(parseInt(x))}

export type CharacterID = number;

export function yCharacterToSettableCharacterNow(yCharacter: YCharacter): SettableCharacter {
    const mostOfCharacter = {
        characterID: getValueIfHas<CharacterID>(yCharacter, "characterID", -1),
        characterType: getSettableDirectValueIfHas<CharacterType, CharacterType>(yCharacter, "characterType", CharacterType.PLACEHOLDER, idParse, parsesTrue),
        howMany: getSettableDirectValueIfHas<number, number>(yCharacter, "howMany", 1, idParse, parsesTrue),
        marksOfDeath: getSettableDirectValueIfHas<number, number>(yCharacter, "marksOfDeath", 0, idParse, parsesTrue),
        // TODO character roll history
        name: getSettableFieldValueIfHas<string, string>(yCharacter, "name", "fake", idParse, isNotEmpty),
        nickname: getSettableFieldValueIfHas<string, string>(yCharacter, "nickname", "fake", idParse, isNotEmpty),
        actionValue: getSettableFieldValueIfHas<number, string>(yCharacter, "actionValue", "0", parseInt, parsesAsInt),
        attackType: getSettableDirectValueIfHas<AttackType, AttackType>(yCharacter, "attackType", AttackType.ATTACK, idParse, parsesTrue),
        weaponDamage: getSettableFieldValueIfHas<number, string>(yCharacter, "weaponDamage", "0", parseInt, parsesAsInt),
        defense: getSettableFieldValueIfHas<number, string>(yCharacter, "defense", "0", parseInt, parsesAsInt),
        toughness: getSettableFieldValueIfHas<number, string>(yCharacter, "toughness", "0", parseInt, parsesAsInt),
        woundPoints: getSettableFieldValueIfHas<number, string>(yCharacter, "woundPoints", "0", parseInt, parsesAsInt),
        fortune: getSettableFieldValueIfHas<number, number>(yCharacter, "fortune", 0, idParse, parsesTrue),
        fortuneType: getSettableDirectValueIfHas<FortuneType, FortuneType>(yCharacter, "fortuneType", FortuneType.FORTUNE, idParse, parsesTrue),
        speed: getSettableFieldValueIfHas<number, string>(yCharacter, "speed", "0", parseInt, parsesAsInt),
        defenseBonus: getSettableFieldValueIfHas<number, number>(yCharacter, "defenseBonus", 0, idParse, parsesTrue),
        toughnessBonus: getSettableFieldValueIfHas<number, number>(yCharacter, "toughnessBonus", 0, idParse, parsesTrue),
        extraImpairment: getSettableFieldValueIfHas<number, number>(yCharacter, "extraImpairment", 0, idParse, parsesTrue),
        visible: getSettableFieldValueIfHas<boolean, boolean>(yCharacter, "visible", false, idParse, parsesTrue),
        dead: getSettableFieldValueIfHas<boolean, boolean>(yCharacter, "dead", false, idParse, parsesTrue),
        nextShot: getSettableDirectValueIfHas<number, string>(yCharacter, "nextShot", "-1", parseInt, parsesAsInt),
        nextShotSetAt: getSettableDirectValueIfHas<number, number>(yCharacter, "nextShotSetAt", -1, idParse, parsesTrue)
    }
    const impairment = calculateImpairment(mostOfCharacter.woundPoints.value) + mostOfCharacter.extraImpairment.value;
    const totalDef = mostOfCharacter.defense.value + mostOfCharacter.defenseBonus.value - impairment;
    const totalToughness = mostOfCharacter.toughness.value + mostOfCharacter.toughnessBonus.value;
    const totalAV = mostOfCharacter.actionValue.value - impairment;
    return {
        ...mostOfCharacter,
        impairment: impairment,
        totalAV: totalAV,
        totalDefense: totalDef,
        totalToughness: totalToughness
    }
}

export function yArray2SettablesMap(yArray: Y.Array<YCharacter>): Map<CharacterID, SettableCharacter> {
    const jsCharacters = yArray.toArray().map(yCharacterToSettableCharacterNow);
    const cMap = new Map<CharacterID, SettableCharacter>();
    for (const c of jsCharacters) {
        cMap.set(c.characterID, c)
    }
    return cMap
}


export function handleYCharacterDeletion(yCharacterList: YCharacterList, characterToDelete: SettableCharacter | null) {
  if (characterToDelete === null) {
      return;
  }
  let foundCharacter = false;
  let foundCharacterIndex = -1;
  for (const [index, char] of yCharacterList.toArray().entries()) {
      if (char.get("characterID") === characterToDelete.characterID) {
          foundCharacter = true;
          foundCharacterIndex = index
      }
  }
  if (foundCharacter) {
      console.log("deleting character")
      yCharacterList.delete(foundCharacterIndex, 1);
      return;
  }
  console.log("did not find character to delete.")
}

export function mapToMaybeSingleton<K, V>(m: Map<K, V>): Map<K, V> {
    const newMap = new Map<K, V>();
    // noinspection LoopStatementThatDoesntLoopJS
    for (const [key, value] of m) {
        newMap.set(key, value);
        return newMap
    }
    return newMap;
}

export function lookupMaybeSingleton<K, V, O>(map: Map<K, V>, other: Map<K, O>): O | null {
    for (const [k, _] of map.entries()) {
        const maybeOtherValue = other.get(k);
        if (maybeOtherValue !== undefined) {
            return maybeOtherValue
        }
    }
    return null;
}


export function newMapWith<T,V>(map: Map<T,V>, key: T, value:V): Map<T,V> {
    const newMap = new Map();
    for (const [k, v] of map.entries()) {
        newMap.set(k, v)
    }
    newMap.set(key, value);
    return newMap
}
export function newMapWithout<T,V>(map: Map<T, V>, key:T): Map<T,V> {
    const newMap = new Map();
    for (const [k, v] of map.entries()) {
        if (k !== key) {
            newMap.set(k, v)
        }
    }
    return newMap
}

export function newMapWithFewer<T>(map: Map<T, number>, nToRemove: number): Map<T, number> {
    const newMap = new Map();
    let nLeftToRemove = nToRemove;
    for (const [k, nRemovable] of map.entries()) {
        if (nRemovable <= nLeftToRemove) {
            nLeftToRemove -= nRemovable;
        } else {
            const nRemainingAfterRemoval = nRemovable - nLeftToRemove;
            nLeftToRemove = 0;
            newMap.set(k, nRemainingAfterRemoval)
        }
    }
    return newMap;
}

export type SetReactState<T> = React.Dispatch<React.SetStateAction<T>>

// export enum HowSelectable {
//     Unselectable,
//     OneSelectable,
//     MultipleSelectable
// }


export type KFEventID = number;

export interface KeyFrameEvent {
    eventID: KFEventID,
    name: Settable<string, string>,
    endSequence: Settable<number, string>,
    endAtStartOfShot: Settable<number, string>
}
export type YKeyFrameEvent = Y.Map<any>;

export function newKeyFrameEvent(yArray: Y.Array<YKeyFrameEvent>, endSequence: number, endAtStartOfShot: number) {
    const newYEvent = new Y.Map<any>();
    const eventID = newRandomID()
    newYEvent.set("eventID", eventID);
    newYEvent.set("name", "Event name");
    newYEvent.set("endSequence", String(endSequence));
    newYEvent.set("endAtStartOfShot", String(endAtStartOfShot))
    yArray.push([newYEvent]);
    return eventID

}

export function yKeyFrameEventToSettableKeyFrameEventNow(yKeyFrameEvent: YKeyFrameEvent): KeyFrameEvent {
    return {
        eventID: getValueIfHas<number>(yKeyFrameEvent, "eventID", -1),
        name: getSettableDirectValueIfHas<string, string>(yKeyFrameEvent,
            "name", "new keyframe event", idParse, isNotEmpty),
        endSequence: getSettableDirectValueIfHas<number, string>(yKeyFrameEvent,
            "endSequence", "2", parseInt, parsesAsInt),
        endAtStartOfShot: getSettableDirectValueIfHas<number, string>(yKeyFrameEvent,
        "endAtStartOfShot", "-1", parseInt, parsesAsInt)
    }
}

export function yArray2SettablesKeyframeMap(yArray: Y.Array<YKeyFrameEvent>): Map<KFEventID, KeyFrameEvent> {
    const jsArray = yArray.toArray().map(yKeyFrameEventToSettableKeyFrameEventNow);
    const kfeMap = new Map<KFEventID, KeyFrameEvent>();
    for (const kfe of jsArray) {
        kfeMap.set(kfe.eventID, kfe)
    }
    return kfeMap
}

export function handleKFEventDeletion(yArray: Y.Array<YKeyFrameEvent>, eventIDsToDelete: KFEventID[]): void {
    const kfEvents = yArray.toArray().map(yKeyFrameEventToSettableKeyFrameEventNow);
    const indicesToDelete = []
    for (const [index, kfEvent] of kfEvents.entries()) {
        if (eventIDsToDelete.includes(kfEvent.eventID)) {
            indicesToDelete.push(index)
        }
    }
    const deletionOrder = indicesToDelete.sort().reverse();
    for (const index of deletionOrder) {
        yArray.delete(index, 1)
    }
}

const nToPips = (new Map<number, string>()
        .set(-1, "\u25A1")
        .set(0, "\u25A3")
        .set(1, "⚀")
        .set(2, "⚁")
        .set(3, "⚂")
        .set(4, "⚃")
        .set(5, "⚄")
        .set(6, "⚅")
);

export function formatRolls(arr: number[], nBoxcars: number, className: string,
                            nResolved: number, showAll = false): JSX.Element[] {
    const newArr = [];
    for (const [index, val] of arr.entries()) {
        const isBoxcar = (index < nBoxcars);
        const isResolved = index < nResolved;
        const willShow = index < nResolved + 1 || showAll;
        if (willShow) {
            const isSix = val === 6;
            let specialCSSClass = "";
            if (!isResolved) {
                const spinClass = Math.min(9,Math.floor(Math.random()*10))
                specialCSSClass = "spinningDie" + spinClass + " "
            } else if (isBoxcar) {
                specialCSSClass = "boxcarDie "
            } else if (isSix) {
                specialCSSClass = "sixDie "
            }
            const shape = isResolved ? nToPips.get(isResolved ? val : -1) : "\u25A1";
            const cssClass = specialCSSClass + "die " + className
            const newVal = <span className={cssClass} key={String(index)+String(val)}>
                {shape}
            </span>
            newArr.push(newVal);
        }

    }
    return newArr;
}

export interface DiceRoll {
    plusses: number[],
    minuses: number[],
    rawFortunes: number[],
}

export function diceRollMaxLength(diceRoll: DiceRoll): number {
    return Math.max(diceRoll.plusses.length, diceRoll.minuses.length);
}

export function formatNumNamed(n: string | number, name: string): string {
    return "(" + name + ": " + n + ")"

}