import UsernameInput from "./UsernameInput";
import {CharacterID, mapToMaybeSingleton, SetReactState} from "./utils";
import React from "react";

interface Props {
    userName: string,
    usersPresent: Map<number, string>,
    handleUserNameChange: (_: {target: {value: string}}) => void,
    isUsingDMMode: boolean, setIsUsingDMMode: SetReactState<boolean>,
    deletionActive: boolean, setDeletionActive: SetReactState<boolean>,
    setEnemyIdsToNSelected: SetReactState<Map<CharacterID, number>>,
    setPCIDsToNSelected: SetReactState<Map<CharacterID, number>>,
}

export default function Header(props: Props) {
    const {
        usersPresent,
        userName, handleUserNameChange,
        isUsingDMMode, setIsUsingDMMode,
        deletionActive, setDeletionActive,
        setEnemyIdsToNSelected,
        setPCIDsToNSelected
    } = props;
    const usersNames = Array.from(usersPresent).map(
      ([userID, userName]) => (<div key={userID}>{userName}</div>)
    );

    const handleToggleDMMode = () => {
      const newIsUsingDMMode = !isUsingDMMode;
      setIsUsingDMMode(newIsUsingDMMode);
      // make sure only one attacker is selected
      if (newIsUsingDMMode) {
          // attackers are enemies
          setEnemyIdsToNSelected(prev => mapToMaybeSingleton(prev));
      } else {
          // attackers are PCs
          setPCIDsToNSelected(prev => mapToMaybeSingleton(prev));
      }
    }
    const dmModeComponent = <div><label htmlFor="setDMMode">
        DM Mode
        <input type="checkbox" checked={isUsingDMMode}
               id="setDMMode"
               name="setDMMode"
               onChange={handleToggleDMMode}/>
    </label></div>
    const deletionActiveComponent = <div><label htmlFor="setDeletionActive">
        Deletion Active
        <input type="checkbox" checked={deletionActive}
               id="setDeletionActive"
               name="setDeletionActive"
               onChange={() => setDeletionActive(!deletionActive)}/>
    </label></div>
    return (
        <div className="header">
            <UsernameInput handleUserNameChange={handleUserNameChange}
                userName={userName}/>
            <div className="headerToggles">
                {dmModeComponent}
                {deletionActiveComponent}
            </div>
            <div className="usersPresent">
                <div className="usersPresentTitle">Characters Present:</div>
                {usersNames}
            </div>
        </div>
    )
}