
interface Props {
    text: string | number | JSX.Element | JSX.Element[],
    tooltipText: string | number | JSX.Element | JSX.Element[],
    extraClassNames?: [string],
    extraToolTipTextClassNames?: [string]
}

export function TooltipSpan(props: Props) {
    const {text, tooltipText, extraClassNames, extraToolTipTextClassNames} = props
    const classNames = ["tooltip"];
    if (extraClassNames !== undefined) {
        for (const name of extraClassNames) {
            classNames.push(name)
        }
    }
    const toolTipTextClassNames = ["tooltipText"];
    if (extraToolTipTextClassNames !== undefined) {
        for (const name of extraToolTipTextClassNames) {
            toolTipTextClassNames.push(name)
        }
    }
    return (
        <span className={classNames.join(" ")}>
            {text}
            <span className={toolTipTextClassNames.join(" ")}>
                {tooltipText}
            </span>
        </span>
    )
}
