import React from "react";

interface StringTableProps {
    entries: string[]
    maxWidth ?: number
}

export function StringTable(props: StringTableProps) {
    const {entries, maxWidth} = props;
    const tableWidth = Math.min(maxWidth === undefined ? 10 : maxWidth, Math.max(1, Math.floor(Math.sqrt(entries.length))));
    const tableHeight = Math.ceil(entries.length / tableWidth);
    const elements: JSX.Element[][] = Array.from(Array(tableHeight), () => []);
    for (let i = 0; i < entries.length; i++) {
        const usage = entries[i];
        elements[i % tableHeight].push(<td key={usage}>{usage}</td>)
    }
    const rows: JSX.Element[] = []
    for (let i = 0; i < tableHeight; i++) {
        rows[i] = <tr key={i}>
            {elements[i]}
        </tr>
    }

    return (
        <table>
            <tbody>
            {rows}
            </tbody>
        </table>
    )
}