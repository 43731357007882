import React from "react"
import CharacterList from "./CharacterList";
import {CharacterID, CharacterListType, SetReactState, SettableCharacter, YCharacterList} from "./utils";

interface Props {
    yPCs: YCharacterList,
    yEnemies: YCharacterList,
    pcIDsToNSelected: Map<CharacterID, number>,
    setPcIDsToNSelected: SetReactState<Map<CharacterID, number>>,
    enemyIDsToNSelected: Map<CharacterID, number>,
    setEnemyIDsToNSelected: SetReactState<Map<CharacterID, number>>,
    pcs: Map<CharacterID, SettableCharacter>,
    enemies: Map<CharacterID, SettableCharacter>,
    isUsingDMMode: boolean,
    maxTargetsSelectable: number,
    potentialAttacker: SettableCharacter | null
}

export default function CharactersSection(props: Props) {
    const {
        yPCs, yEnemies,
        pcIDsToNSelected,
        setPcIDsToNSelected,
        enemyIDsToNSelected,
        setEnemyIDsToNSelected,
        pcs,
        enemies,
        isUsingDMMode,
        maxTargetsSelectable,
        potentialAttacker
    } = props;
    return (
        <div className="charactersSection">
            <div className="characterListHeading">PCs</div>
            <CharacterList
                yArray={yPCs}
                characterListType={CharacterListType.PC} characters={pcs}
                characterIDsToCountSelected={pcIDsToNSelected}
                setCharacterIDsToCountSelected={setPcIDsToNSelected}
                readOnly={false}
                isUsingDMMode={isUsingDMMode}
                maxSelectable={isUsingDMMode ? maxTargetsSelectable : 1}
                isTarget={isUsingDMMode}
                isTargetSection={false}
                potentialAttacker={potentialAttacker}
            />
            <hr/>
            <div className="characterListHeading">Bad news</div>
            <CharacterList
                yArray={yEnemies}
                characterListType={CharacterListType.ENEMY} characters={enemies}
                characterIDsToCountSelected={enemyIDsToNSelected}
                setCharacterIDsToCountSelected={setEnemyIDsToNSelected}
                readOnly={!isUsingDMMode}
                isUsingDMMode={isUsingDMMode}
                maxSelectable={isUsingDMMode ? 1 : maxTargetsSelectable}
                isTarget={!isUsingDMMode}
                isTargetSection={false}
            />
        </div>
    )
}