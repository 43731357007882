import {Link} from "react-router-dom";

export function Nav() {
    return (<div className="nav">
        <Link to="/">Home</Link>
        <Link to="/fengshui">Feng Shui 2</Link>
        <Link to="/names_by_type">Names by type</Link>
        <Link to="/names_by_usage">Names by usage</Link>
    </div>

    )
}