import nameDataRaw from "./btn.json"
import React, {useState} from "react";
import {getRandomSubarray} from "../fengShui/mathUtils";
import {SetReactState} from "../fengShui/utils";
import {StringTable} from "./StringTable";



type Usage = string;
type Gender = string;

const nameData = new Map<Gender, Map<Usage, string[]>>();
const nameUsages: string[] = [];
const nameGenders: string[] = [];
const nameUsageCount = new Map<Gender, Map<Usage, number>>();
for (const [nameGender, genderedNamesByUsage] of Object.entries(nameDataRaw)) {
    const usage2list = new Map<Usage, string[]>();
    const usage2count = new Map<Usage, number>();
    for (const [nameType, nameList] of Object.entries(genderedNamesByUsage)) {
        usage2list.set(nameType, nameList)
        usage2count.set(nameType, nameList.length);
        if (!nameUsages.includes(nameType)) {
            nameUsages.push(nameType)
        }
    }
    nameData.set(nameGender, usage2list);
    nameUsageCount.set(nameGender, usage2count)
    nameGenders.push(nameGender)
}

interface NameLanguageKeyProps {
    setSelectedLanguage: SetReactState<string | null>
    minCountInclusive: number,
    maxCountExclusive: number,
    countMap: Map<Usage, number>
    reRender: () => void
}
function NameLanguageKey(props: NameLanguageKeyProps) {
    const {setSelectedLanguage, minCountInclusive, maxCountExclusive, countMap, reRender} = props;
    const isInRange = (lang: string) => {
        console.log(lang)
        const lang_count = countMap.get(lang)
        console.log(lang_count)
        if (lang_count === undefined) {
            return false
        }
        return lang_count >= minCountInclusive && lang_count < maxCountExclusive
    }
    const eligibleLanguages = nameUsages.filter(isInRange)
    const tableWidth = Math.min(10,Math.max(1,Math.floor(Math.sqrt(eligibleLanguages.length)*0.5)));
    const tableHeight = Math.ceil(eligibleLanguages.length/tableWidth);
    const elements: JSX.Element[][] = Array.from(Array(tableHeight), () => []);
    for (let i = 0; i < eligibleLanguages.length; i++) {
        const usage = eligibleLanguages[i];
        const handler = () => {
            console.log("selecting language")
            setSelectedLanguage(usage);
            reRender()
        }
        elements[i % tableHeight].push(<td key={usage} onClick={handler}>{usage}</td>)
    }
    const rows: JSX.Element[] = []
    for (let i = 0; i < tableHeight; i++) {
        rows[i] = <tr key={i}>
            {elements[i]}
        </tr>
    }

    return (
        <table>
            <tbody>
                {rows}
            </tbody>
        </table>
    )
}


interface ExpandableProps {
    component: JSX.Element
    label: string
}
function Expandable(props: ExpandableProps) {
    const {component, label} = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const button =<button onClick={() => setIsExpanded(!isExpanded)}>{(isExpanded ? "(-) " : "(+) " ) + label}</button>
    if (isExpanded) {
        return (
            <div>
                {button}
                {component}
            </div>
        )
    }
    return (
        <div>
            {button}
        </div>
    )
}

export function NamesByUsage() {
    const [nNames, setNNames] = useState<number>(100);
    const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
    const [selectedGender, setSelectedGender] = useState<string>("Any");
    const [unused, setUnused] = useState(false);
    const reRender = () => setUnused(!unused);

    const maybeNameMap : undefined | Map<Usage, string[]> = nameData.get(selectedGender);
    let nameMap: Map<Usage, string[]>
    if (maybeNameMap === undefined) {
        nameMap = new Map<Usage, string[]>();
    } else {
        nameMap = maybeNameMap
    }

    const maybeCountMap : undefined | Map<Usage, number> = nameUsageCount.get(selectedGender);
    let countMap: Map<Usage, number>
    if (maybeCountMap === undefined) {
        countMap = new Map<Usage, number>();
    } else {
        countMap = maybeCountMap
    }

    const nNameOptions = [1,5,20,50,100,1000].map((n) => (<option className={"nerNNamesOption"} key={n}>{n}</option> ))
    const handleNNamesChange = ({target: {value}} : {target: {value: string}}): void => {
        setNNames(parseInt(value))
    }
    const nNamesSelector = <div className="nerNNamesSelector">
        # of names:
        <select value={nNames} onChange={handleNNamesChange}>
            {nNameOptions}
        </select>
    </div>

    const genderOptions = nameGenders.map((g) => <option className="nerGenderOption" key={g}>{g}</option>)
    const handleGenderChange = ({target: {value}} : {target: {value: string}}): void => {
        setSelectedGender(value)
    }
    const genderSelector = <div className="nerGenderSelector">
        Gender:
        <select value={selectedGender} onChange={handleGenderChange}>
            {genderOptions}
        </select>
    </div>
    const languageKeys = <div className="languageKeys">
        <Expandable label="large (> 1000)" component={<NameLanguageKey countMap={countMap} reRender={reRender} setSelectedLanguage={setSelectedLanguage} minCountInclusive={1000} maxCountExclusive={Number.POSITIVE_INFINITY}/>}/>
        <Expandable label="medium (100 - 1000)" component={<NameLanguageKey countMap={countMap} reRender={reRender} setSelectedLanguage={setSelectedLanguage} minCountInclusive={100} maxCountExclusive={1000}/>}/>
        <Expandable label="small (10 - 100)" component={<NameLanguageKey countMap={countMap} reRender={reRender} setSelectedLanguage={setSelectedLanguage} minCountInclusive={10} maxCountExclusive={100}/>}/>
        <Expandable label="very small (1 - 10)" component={<NameLanguageKey countMap={countMap} reRender={reRender} setSelectedLanguage={setSelectedLanguage} minCountInclusive={0} maxCountExclusive={10}/>}/>
    </div>
    if (selectedLanguage === null) {
        return (<div className="nerNames">
            {languageKeys}
            <hr/>
            <div className="nerSelectors">
                {nNamesSelector}
            </div>
        </div>)
    }
    const nameList = nameMap.get(selectedLanguage)
    if (nameList === undefined) {
        return (<div className="nerNames">
            {languageKeys}
            <hr/>
            <div className="nerSelectors">
                {nNamesSelector}
            </div>
        </div>)
    }
    const nameListSample = getRandomSubarray(nameList, nNames);
    let count : number | undefined | null = countMap.get(selectedLanguage)
    let countElement;
    if (count === undefined) {
        countElement = null
    } else {
        countElement = count + " names available"
    }
    return (<div className="nerNames">
            {languageKeys}
            <hr/>
            <div className="nerSelectors">
                {genderSelector}
                {nNamesSelector}
                <button onClick={reRender}>Resample</button>
            </div>
            <hr/>
            <div className="nNamesAvailable">{selectedLanguage}: {countElement}</div>
            <StringTable entries={nameListSample}/>
        </div>)

}