import './App.css';
import React, {useEffect, useState} from "react";
import * as Y from "yjs";
import {WebsocketProvider} from "y-websocket";
import {
    CharacterID, CharacterType, lookupMaybeSingleton,
    SetReactState,
    SettableCharacter,
    yArray2SettablesMap,
    YCharacter,
    YCharacterList,
} from "./utils";
import {newRandomID} from "./mathUtils";
import Header from "./Header";
import ShotTracker from "./ShotTracker";
import CharactersSection from "./CharactersSection";
import AttackSection from "./AttackSection";

const websocketHost = (
    process.env.NODE_ENV === "production" ?
        "wss://bog-sockets.ian-horn.com" :
        "ws://localhost:1234"
)
console.log("websocket host:", websocketHost)

const yDoc = new Y.Doc();
const provider = new WebsocketProvider(
    websocketHost,
    'bog-room',
    yDoc
);
const awareness = provider.awareness;

// On mount, set up awareness observer for users present
function useOtherUsersAwareness(userName: string, userID: number, setUsersPresent: SetReactState<Map<number,string>>) {
    useEffect(() => {
        // Set my username for everyone else
        awareness.setLocalStateField("user", {userName: userName,userID:userID});
        // And watch everyone else's for changes
        const observer = () => {
          console.log("awareness changed");
          const users = new Map<number, string>().set(userID, userName);
          awareness.getStates().forEach(state => {
            if (state.user) {
                users.set(state.user.userID, state.user.userName)
            }
          })
          console.log("... users: ", users)
          setUsersPresent(users);
        }
        awareness.on("change", observer);
        return () => awareness.off("change", observer)
    }, [userName, userID, setUsersPresent])
}

function updateNSelectedWithCharacters(counts: Map<CharacterID, number>, characters: Map<CharacterID, SettableCharacter>): Map<CharacterID,number> {
    const newCounts = new Map<CharacterID, number>();
    for (const [id, count] of counts.entries()) {
        const character = characters.get(id);
        if (character !== undefined && character.howMany.value > 0) {
            newCounts.set(id, Math.min(character.howMany.value, count))
        }
    }
    return newCounts
}

function useCharactersObserverEffect(yCharacters: YCharacterList, setAll: SetReactState<Map<CharacterID,SettableCharacter>>,
                                     setSelectedCounts: SetReactState<Map<CharacterID,number>>) {
    useEffect(() => {
        const observer = () => {
            console.log("character changes")
            const allCharacters = yArray2SettablesMap(yCharacters)
            // Update the character objects
            setAll(allCharacters);
            // Update the counts of selected targets
            setSelectedCounts(prev => updateNSelectedWithCharacters(prev, allCharacters));
        }
        observer()
        yCharacters.observeDeep(observer);
        return () => yCharacters.unobserveDeep(observer)
  }, []);
}




export default function App() {
    console.log("app rendering")
    // Get characters
    const yPCs= yDoc.getArray<YCharacter>("pcs");
    const yEnemies= yDoc.getArray<YCharacter>("enemies");
    // ...turn them into javascript objects with setters
    const [pcs, setPCs] = useState<Map<CharacterID, SettableCharacter>>(yArray2SettablesMap(yPCs));
    const [enemies, setEnemies] = useState<Map<CharacterID, SettableCharacter>>(yArray2SettablesMap(yEnemies));
    // ...get how many of each are selected
    const [pcIDsToNSelected, setPcIDsToNSelected] = useState<Map<CharacterID, number>>(new Map());
    const [enemyIDsToNSelected, setEnemyIDsToNSelected] = useState<Map<CharacterID, number>>(new Map());
    // ... and watch them for changes (local or remote)
    useCharactersObserverEffect(yPCs, setPCs, setPcIDsToNSelected);
    useCharactersObserverEffect(yEnemies, setEnemies, setEnemyIDsToNSelected);
    // Get a username/id
    const [userName, setUserName] = useState<string>("Unnamed Character");
    const [userID] = useState<number>(newRandomID());
    const handleUserNameChange = ({target: {value}} : {target: {value:string}}): void  => {
        console.log("username change", value);
        awareness.setLocalStateField("user", {userName: value,userID:userID});
        setUserName(value);
    }
    // And get/set everyone else's
    const [usersPresent, setUsersPresent] = useState<Map<number, string>>(new Map().set(userID, userName));
    useOtherUsersAwareness(userName, userID, setUsersPresent);
    // Set up some state utils
    const [isUsingDMMode, setIsUsingDMMode] = useState<boolean>(false);
    const [deletionActive, setDeletionActive] = useState<boolean>(false);

    // what's selected
    const [attackers, selectedAttackers, yAttackers] = isUsingDMMode ? [enemies, enemyIDsToNSelected, yEnemies] : [pcs, pcIDsToNSelected, yPCs];
    const [targets, selectedTargets] = isUsingDMMode ? [pcs, pcIDsToNSelected] : [enemies, enemyIDsToNSelected]
    let selectedAttacker = lookupMaybeSingleton(selectedAttackers, attackers)
    let maxTargetsSelectable = Number.POSITIVE_INFINITY;
    if (selectedAttacker !== null && selectedAttacker.characterType.value === CharacterType.MOOK) {
        maxTargetsSelectable = selectedAttacker.howMany.value;
    }


    // Set up components
    return (
        <div className="app">
            <Header userName={userName}
                    usersPresent={usersPresent}
                    handleUserNameChange={handleUserNameChange}
                    isUsingDMMode={isUsingDMMode}
                    setIsUsingDMMode={setIsUsingDMMode}
                    deletionActive={deletionActive}
                    setDeletionActive={setDeletionActive}
                    setEnemyIdsToNSelected={setEnemyIDsToNSelected}
                    setPCIDsToNSelected={setPcIDsToNSelected}/>
            <hr/>
            <div className="charactersAndShotTracker">
                <CharactersSection
                    yPCs={yPCs} yEnemies={yEnemies}
                    pcIDsToNSelected={pcIDsToNSelected} setPcIDsToNSelected={setPcIDsToNSelected}
                    enemyIDsToNSelected={enemyIDsToNSelected} setEnemyIDsToNSelected={setEnemyIDsToNSelected}
                    pcs={pcs} enemies={enemies}
                    isUsingDMMode={isUsingDMMode}
                    maxTargetsSelectable={maxTargetsSelectable}
                    potentialAttacker={selectedAttacker}
                />
                <ShotTracker
                    yDoc={yDoc}
                    pcs={[...pcs.values()]}
                    enemies={[...enemies.values()]}
                    isUsingDMMode={isUsingDMMode}
                    deletionActive={deletionActive}
                    selectedAttacker={selectedAttacker}
                    setPcIDsToNSelected={setPcIDsToNSelected}
                    setEnemyIDsToNSelected={setEnemyIDsToNSelected}
                />
            </div>
            <AttackSection
                selectedAttacker={selectedAttacker}
                isUsingDMMode={isUsingDMMode}
                deletionActive={deletionActive}
                yAttackers={yAttackers}
                targets={targets}
                selectedTargets={selectedTargets}
            />
        </div>
    )
}
