import {
    CharacterID,
    CharacterType, formatRolls,
    getValueIfHas, handleKFEventDeletion,
    KeyFrameEvent,
    KFEventID, newKeyFrameEvent,
    SetReactState,
    SettableCharacter,
    yArray2SettablesKeyframeMap,
    YKeyFrameEvent
} from "./utils";
import * as Y from "yjs";
import React, {useEffect, useState} from "react";
import {roll1d6} from "./mathUtils";
import SelectedKeyFrameEvent from "./SelectedKeyFrameEvent";
import {UpDownButton} from "./UpDownButton";


function useYSeqNumber(ySequenceNumber: Y.Map<any>, setSequenceNumber: SetReactState<number>): void {
    useEffect(() => {
        setSequenceNumber(getValueIfHas(ySequenceNumber, "value", 1))
        const observer = () => {
            setSequenceNumber(ySequenceNumber.get("value"))
        }
        ySequenceNumber.observe(observer)
        return () => ySequenceNumber.unobserve(observer)
    }, [ySequenceNumber, setSequenceNumber])
}

function useYKeyFrameEvents(yKeyFrameEvents: Y.Array<YKeyFrameEvent>,
                            setKeyFrameEvents: SetReactState<Map<KFEventID, KeyFrameEvent>>,
                            setSelectedKFEventID: SetReactState<number|null>,
                            ) {
    useEffect(() => {
        const observer = () => {
            const kfEvents = yArray2SettablesKeyframeMap(yKeyFrameEvents);
            setKeyFrameEvents(kfEvents);
            setSelectedKFEventID(prev => {
                if (prev === null) {
                    return null
                }
                if (!kfEvents.has(prev)) {
                    return null
                }
                return prev
            })
        }
        observer()
        yKeyFrameEvents.observeDeep(observer)
        return () => yKeyFrameEvents.unobserveDeep(observer)
    }, [yKeyFrameEvents, setKeyFrameEvents, setSelectedKFEventID])
}

interface Props {
    pcs: SettableCharacter[],
    enemies: SettableCharacter[],
    isUsingDMMode: boolean,
    deletionActive: boolean,
    yDoc: Y.Doc,
    setPcIDsToNSelected: SetReactState<Map<CharacterID, number>>,
    setEnemyIDsToNSelected: SetReactState<Map<CharacterID, number>>,
    selectedAttacker: SettableCharacter | null;
}

export default function ShotTracker(props: Props) {
    const {pcs, enemies, isUsingDMMode, yDoc, selectedAttacker, deletionActive,
        setPcIDsToNSelected,
    setEnemyIDsToNSelected
    } = props;
    // everyone visible, but PCs first;
    const everybody = [...pcs, ...enemies].filter(c => c.visible.value && !isNaN(c.nextShot.value) && c.nextShot.value > 0);

    // Get keyframe data
    const ySequenceNumber = yDoc.getMap("sequenceNumber");
    const yKeyFrameEvents = yDoc.getArray<YKeyFrameEvent>("keyFrameEvents");
    // ... turn them into js objects with setters
    const [sequenceNumber, setSequenceNumber] = useState<number>(getValueIfHas(ySequenceNumber, "value", 1));
    const [keyFrameEvents, setKeyFrameEvents] = useState<Map<KFEventID, KeyFrameEvent>>(yArray2SettablesKeyframeMap(yKeyFrameEvents))
    // ... get the selected KF event (if any)
    const [selectedKFEventID, setSelectedKFEventID] = useState<KFEventID|null>(null);
    // ... set state for rolling animation
    const [initiativeDieValue, setInitiativeDieValue] = useState<number|null>(null)
    const [isRolling, setIsRolling] = useState(false)

    useEffect(() => {
        if (isRolling) {
            const dieRoll = roll1d6()
            setInitiativeDieValue(dieRoll)
            const timeout = setTimeout(() => {
                if (selectedAttacker !== null) {
                    setIsRolling(false)
                    const initiative = selectedAttacker.speed.value + dieRoll;
                    selectedAttacker.nextShot.set(String(initiative));
                    selectedAttacker.nextShotSetAt.set(Date.now());
                }

            }, Math.random()*1000+500)
            return () => clearTimeout(timeout)
        }
    }, [isRolling, selectedAttacker])
    // ... and watch them for changes (local or remote)
    useYSeqNumber(ySequenceNumber, setSequenceNumber);
    useYKeyFrameEvents(yKeyFrameEvents, setKeyFrameEvents, setSelectedKFEventID);


    // map characters to shots
    const characterShotMap = new Map<number, SettableCharacter[]>();
    let maxNumericCharacterShot = Number.NEGATIVE_INFINITY;
    everybody.forEach(character => {
        const nextShot = character.nextShot.value;
        let shotCharacters = characterShotMap.get(nextShot);
        if (shotCharacters === undefined) {
            shotCharacters = []
            characterShotMap.set(nextShot, shotCharacters);
        }
        shotCharacters.push(character)
        if (isFinite(nextShot)) {
            maxNumericCharacterShot = Math.max(maxNumericCharacterShot, nextShot);
        }
    })
    const kfShotMap = new Map<number, KeyFrameEvent[]>();
    const nonNumericKFEs : KeyFrameEvent[] = []
    let maxNumericKFShot = Number.NEGATIVE_INFINITY;
    let anyKFShots = false;
    keyFrameEvents.forEach((kfEvent, _) => {
        const nextShot = kfEvent.endAtStartOfShot.value;
        let shotEvents = kfShotMap.get(nextShot);
        if (shotEvents === undefined) {
            shotEvents = []
            kfShotMap.set(nextShot, shotEvents);
        }
        shotEvents.push(kfEvent)
        if (isFinite(nextShot)) {
            maxNumericKFShot = Math.max(maxNumericKFShot, nextShot);
        } else {
            nonNumericKFEs.push(kfEvent)
        }
        anyKFShots = true;
    })
    let zeroShotKFEs = kfShotMap.get(0);
    if (zeroShotKFEs === undefined) {
        zeroShotKFEs = []
        kfShotMap.set(0, zeroShotKFEs)
    }
    for (const kfEvent of nonNumericKFEs) {
        zeroShotKFEs.push(kfEvent)
    }

    // break ties
    const characterShotMapOrdered = new Map<number, SettableCharacter[]>();
    let maxCharactersPerShot = Number.NEGATIVE_INFINITY;
    characterShotMap.forEach((characters, shot) => {
        const charactersOrdered = [...characters].sort((a, b) => {
            if (a.characterType.value === CharacterType.PC && b.characterType.value !== CharacterType.PC) {
                return -1;
            }
            if (a.characterType.value !== CharacterType.PC && b.characterType.value === CharacterType.PC) {
                return 1;
            }
            return a.nextShotSetAt.value - b.nextShotSetAt.value;
        });
        characterShotMapOrdered.set(shot, charactersOrdered);
        maxCharactersPerShot = Math.max(maxCharactersPerShot, charactersOrdered.length)
    })
    let maxKFEventsPerShot = Number.NEGATIVE_INFINITY;
    kfShotMap.forEach((kfEvents, _) => {
        maxKFEventsPerShot = Math.max(maxKFEventsPerShot, kfEvents.length)
    })

    const rollForACharacter = (character: SettableCharacter) => {
        const rollValue = roll1d6()
        const initiative = character.speed.value + rollValue;
        character.nextShot.set(String(initiative));
        character.nextShotSetAt.set(Date.now());
    }

    const clearRollForACharacter = (character: SettableCharacter) => {
        character.nextShot.set("-1");
        character.nextShotSetAt.set(Date.now());
    }
    // set up initiativeRoller
    let rollInitiativeButton: JSX.Element;
    let clearInitiativeButton: JSX.Element | null;
    if (isUsingDMMode) {
        const handleRollInitiative = () => {
            // setInitiativeDieValue(-1);
            enemies.forEach((enemy, _) =>
                rollForACharacter(enemy)
            )
            setInitiativeDieValue(0);
        }
        rollInitiativeButton = <button onClick={handleRollInitiative}>Roll initiative!</button>
        const handleClearInitiative = () => {
            enemies.forEach((enemy, _) =>
                clearRollForACharacter(enemy)
            )
            pcs.forEach((pc, _) =>
                clearRollForACharacter(pc)
            )
        }
        clearInitiativeButton = deletionActive ? (<button onClick={handleClearInitiative}>Clear initiative</button>) : null;
    }
    else if (selectedAttacker === null) {
        // No Button!
        rollInitiativeButton = <>Please select an attacker</>
        clearInitiativeButton = null;
    } else {
        rollInitiativeButton = <button onClick={() => setIsRolling(true)}>Roll initiative!</button>
        clearInitiativeButton = null;
    }

    // check whether there's anything to track
    let maxShot = Math.max(maxNumericCharacterShot, maxNumericKFShot);
    if (isNaN(maxShot) || maxShot <= -1) {
        return (
            <div className="shotTracker">
                {rollInitiativeButton}
            </div>
        )
    }
    maxShot = Math.min(20, maxShot);

    // set up shots
    const shots = [];
    for (let shot = maxShot; shot >= 1; shot--) {
        shots.push(shot)
    }
    // set up header
    const headers = []
    for (const shot of shots) {
        const className = (shot !== maxShot ? "leftBorderTH " : "") + "initiativeShotHeader"
        if (shot === -1) {
            headers.push(<th key={shot} className={className}>...</th>)
        } else {
            headers.push(<th key={shot} className={className}>{shot}</th>)
        }
    }
    const hasZero = kfShotMap.has(0);
    if (hasZero) {headers.push(<th key={0} className="initiativeShotHeader leftBorderTH"></th>)}
    // set up characters
    const characterRows = []
    for (let nthCharacter = 0; nthCharacter < maxCharactersPerShot; nthCharacter++) {
        const row = []
        const isLastRow = nthCharacter >= maxCharactersPerShot;
        let className = (isLastRow ? "lastInitiativeCharacterShot " : "") + "initiativeCharacterShot"
        for (const shot of shots) {
            if (shot !== maxShot) {
                className += " leftBorderTD";
            }
            const shotCharacters = characterShotMapOrdered.get(shot);
            let foundCharacter = false
            if (shotCharacters !== undefined) {
                const character = shotCharacters[nthCharacter];
                if (character !== undefined) {
                    foundCharacter = true;
                    const showHowMany = character.howMany.value > 1 || character.characterType.value === CharacterType.MOOK;
                    const xHowManyString = showHowMany ? " x" + character.howMany.value : ""
                    const enemyClassName = character.characterType.value !== CharacterType.PC ? " initiativeEnemyShot" : ""
                    const setter = character.characterType.value === CharacterType.PC ? setPcIDsToNSelected : setEnemyIDsToNSelected;
                    const handleClick = () => {
                        setter(new Map().set(character.characterID, character.howMany.value))
                    }
                    row.push(<td key={shot} className={className+enemyClassName} onClick={handleClick}>{character.nickname.value + xHowManyString}</td>)
                }
            }
            if (!foundCharacter) {
                row.push(<td key={shot} className={className}></td>)
            }
        }
        if (hasZero) {row.push(<td key={0} className={"leftBorderTD initiativeCharacterShot"}></td>)}
        characterRows.push(<tr key={"character"+nthCharacter} className="initiativeCharacterRow">
            {row}
        </tr> )
    }
    // set up keyframe events
    const kfEventRows = []
    for (let nthKFEvent = 0; nthKFEvent < maxKFEventsPerShot; nthKFEvent++) {
        const row = []

        for (const shot of shots) {
            let className = "initiativeKFEShot";
            if (shot !== maxShot) {
                className += " leftBorderTD";
            }
            if (nthKFEvent === 0) {
                className += " firstKFEvent"
            }
            const shotKFEvents = kfShotMap.get(shot);
            let foundKFEvent = false
            if (shotKFEvents !== undefined) {
                const keyFrameEvent = shotKFEvents[nthKFEvent];
                if (keyFrameEvent !== undefined) {
                    foundKFEvent = true;
                    const pastKFESequence = sequenceNumber > keyFrameEvent.endSequence.value;
                    const pastKFEShot = sequenceNumber >= keyFrameEvent.endSequence.value && maxNumericCharacterShot <= keyFrameEvent.endAtStartOfShot.value;
                    if (pastKFESequence || pastKFEShot) {
                        className += " overdueKeyFrameEvent";
                    }
                    row.push(<td key={shot} className={className} onClick={() => setSelectedKFEventID(keyFrameEvent.eventID)}>{keyFrameEvent.name.value}</td>)
                }
            }
            if (!foundKFEvent) {
                row.push(<td key={shot} className={className}></td>)
            }
        }
        if (hasZero) {
            const shot = 0;
            let className = "initiativeKFEShot";
            if (shot !== maxShot) {
                className += " leftBorderTD";
            }
            if (nthKFEvent === 0) {
                className += " firstKFEvent"
            }
            const shotKFEvents = kfShotMap.get(shot);
            let foundKFEvent = false
            if (shotKFEvents !== undefined) {
                const keyFrameEvent = shotKFEvents[nthKFEvent];
                if (keyFrameEvent !== undefined) {
                    foundKFEvent = true;
                    const pastKFESequence = sequenceNumber > keyFrameEvent.endSequence.value;
                    const pastKFEShot = sequenceNumber >= keyFrameEvent.endSequence.value && maxNumericCharacterShot <= keyFrameEvent.endAtStartOfShot.value;
                    if (pastKFESequence || pastKFEShot) {
                        className += " overdueKeyFrameEvent";
                    }
                    row.push(<td key={0} className={className} onClick={() => setSelectedKFEventID(keyFrameEvent.eventID)}>{keyFrameEvent.name.value}</td>)
                }
            }
        }
        kfEventRows.push(<tr key={"kfe"+nthKFEvent} className="initiativeKFERow">
            {row}
        </tr> )
    }
    let selectedAttackerInitiativeInfo;
    if (selectedAttacker === null) {
        selectedAttackerInitiativeInfo = null
    } else {
        const handleChange = ({target: {value}} : {target: {value: string}}) => {
            selectedAttacker!.nextShot.set(value)
        }
        const htmlKey = "initiativeAdjuster"
        selectedAttackerInitiativeInfo = (
            <div className="textCharacterField" key={htmlKey}>
                <label htmlFor={htmlKey}>
                    Next shot:
                    <input
                      type="text"
                      readOnly={false}
                      id={htmlKey}
                      name={htmlKey}
                      onChange={handleChange}
                      value={selectedAttacker.nextShot.unParsedValue}
                    />
                </label>
            </div>
        )
    }
    const initiativeRoll = (initiativeDieValue === null ? null :
                formatRolls([initiativeDieValue] , 0, "initiativeDie", isRolling ? 0 : 1)
    )

    //keyframe editing
    let selectedKeyFrameComponent;
    if (selectedKFEventID === null) {
        selectedKeyFrameComponent = null;
    } else {
        const selectedKeyFrame = keyFrameEvents.get(selectedKFEventID)
        selectedKeyFrameComponent = <SelectedKeyFrameEvent
            keyFrameEvent={selectedKeyFrame === undefined ? null : selectedKeyFrame}
            handleDeletion={() => handleKFEventDeletion(yKeyFrameEvents, [selectedKFEventID])}
            handleDeselection={() => setSelectedKFEventID(null)}
            isUsingDMMode={isUsingDMMode}
            deletionActive={deletionActive}
        />;
    }

    const handleNewKeyFrameEvent = () => {
        const eventID = newKeyFrameEvent(yKeyFrameEvents, sequenceNumber+1, maxShot)
        setSelectedKFEventID(eventID);
    }
    const newKeyFrameEventButton = (
        <button onClick={handleNewKeyFrameEvent}>
            New key frame event
        </button>
    )

    const handleNewSequenceEvent = () => {
        const eventID = newKeyFrameEvent(yKeyFrameEvents, sequenceNumber, 0)
        setSelectedKFEventID(eventID);
    }
    const newSequenceEventButton = (
        <button onClick={handleNewSequenceEvent}>
            New sequence event
        </button>
    )

    // render
    const sequenceModifierButton = isUsingDMMode ? (
        <UpDownButton label="" value={sequenceNumber} setValue={(value:number) => ySequenceNumber.set("value", value)}
                      minValue={1} maxValue={Number.POSITIVE_INFINITY}
                      showValue={false}/>
    ) : null;
    return (
        <div className="shotTracker">
            <div className="sequenceNumberContainer">
                <div className="sequenceNumber">{"Sequence " + sequenceNumber}</div>
                {sequenceModifierButton}
            </div>
            <table className="shotTrackerTable">
                <thead>
                    <tr>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {characterRows}
                    {kfEventRows}
                </tbody>
            </table>
            <div className="shotTrackerLower">
                <div className="shotTrackerLowerLeft">
                    {selectedAttackerInitiativeInfo}
                    {rollInitiativeButton}
                    {initiativeRoll}
                    {clearInitiativeButton}
                </div>
                <div className="shotTrackerLowerRight">
                    {newKeyFrameEventButton}
                    {newSequenceEventButton}
                    {selectedKeyFrameComponent}
                </div>
            </div>


        </div>
    )
}