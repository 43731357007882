import React from "react";
import {AttackType, CharacterType, FortuneType, Settable, SettableCharacter} from "./utils";
import {UpDownButton} from "./UpDownButton";


interface Props {
    title: string,
    readOnly: boolean,
    character: SettableCharacter | null,
    handleDeletion: () => void,
    isUsingDMMode: boolean,
    deletionActive: boolean
}

export default function SelectedCharacter(props: Props) {
    const {title, readOnly, character,
        handleDeletion,
        isUsingDMMode,
        deletionActive
    } = props;
    if (character === null) {
        return <div>{"Please select an attacker"}</div>
    }
    function SettableTextField<T>(field: string, formLabel: string | JSX.Element) {
        // @ts-ignore
        const characterField: Settable<T, string> = character[field];
        const key = String(character!.characterID) + field;
        const handleChange = ({target: {value}} : {target: {value: string}}) => {
            characterField.set(value)
        }
        let divClassName = "textCharacterField"
        let inputClassName = ""
        if (!characterField.parses) {
            inputClassName += " invalidTextCharacterField"
        }
        return (
            <div className={divClassName} key={key}>
                <label htmlFor={key}>
                    {formLabel}
                    <input className={inputClassName}
                      type="text"
                      readOnly={readOnly}
                      id={key}
                      name={key}
                      onChange={handleChange}
                      value={characterField.unParsedValue}
                    />
                </label>
            </div>
        )
    }
    function intFieldComponent(field: string, formLabel: string | JSX.Element) {
        // TODO be smarter about this
        return SettableTextField<string>(field, formLabel);
    }
    function stringFieldComponent(field: string, formLabel: string | JSX.Element) {
        return SettableTextField<string>(field, formLabel)
    }

    function CharacterTypeComponent() {
        const characterType = character!.characterType.value;
        const options =  Object.values(CharacterType).map((characterTypeString) => (
            <option className={"characterType"} key={characterTypeString}>{characterTypeString}</option>
            )
        )
        const handleChange = ({target: {value}} : {target: {value: string}}): void => {
            character!.characterType.set(value as CharacterType);
        }
        return (
            <div>
                <select value={characterType.toString()} onChange={handleChange}>
                    {options}
                </select>
            </div>
        )
    }

    function FortuneTypeComponent() {
        const fortuneType = character!.fortuneType.value;
        const options =  Object.values(FortuneType).map((fortuneTypeString) => (
            <option className={"fortuneType"} key={fortuneTypeString}>{fortuneTypeString}</option>
            )
        )
        const handleChange = ({target: {value}} : {target: {value: string}}): void => {
            character!.fortuneType.set(value as FortuneType);
        }
        return (
            <div className="fortuneTypeSelector">
                <select value={fortuneType.toString()} onChange={handleChange}>
                    {options}
                </select>
            </div>
        )
    }

    function AttackTypeComponent() {
        const attackType = character!.attackType.value;
        const options =  Object.values(AttackType).map((fortuneTypeString) => (
            <option className={"fortuneType"} key={fortuneTypeString}>{fortuneTypeString}</option>
            )
        )
        const handleChange = ({target: {value}} : {target: {value: string}}): void => {
            character!.attackType.set(value as AttackType);
        }
        return (
            <div className="attackTypeSelector">
                <select value={attackType.toString()} onChange={handleChange}>
                    {options}
                </select>
                {" AV"}
            </div>
        )
    }


    const setHowMany = (value: number) => {
        if (isNaN(value)) {
            character.howMany.set(1)
        } else {
            character.howMany.set(value)
        }
    }
    const deadCheckBox = <div>
        Dead
        <input
            type="checkbox" onChange={() => character.dead.set(!character.dead.value)} checked={character.dead.value}
        />
    </div>
    const visibleCheckBox = <div>
        Visible
        <input
            type="checkbox" onChange={() => character.visible.set(!character.visible.value)} checked={character.visible.value}
        />
    </div>

    // Set up extras
    const deleter = (readOnly || !deletionActive) ? null : (<div><button onClick={handleDeletion}>Delete selected {character.characterType.value}</button></div>);
    const dmExtrasLeft = isUsingDMMode ? (<>
        <CharacterTypeComponent/>
    </>) : null
    const dmExtrasRight = isUsingDMMode ? (<>
        {deadCheckBox}
        {visibleCheckBox}
    </>) : null

    const handleResetBonuses = () => {
        character.defenseBonus.set(0);
        character.toughnessBonus.set(0);
    }

    const howManyComponent = isUsingDMMode ? (<UpDownButton label="How Many" value={character.howMany.value} setValue={setHowMany}
                                                            minValue={1} maxValue={Number.POSITIVE_INFINITY}/>) : null;

    // render
    return (
        <div className="selectedCharacter">
            <div className="selectedCharacterHeading">{title}</div>
            <div className="selectedCharacterColumns">
                <div className="selectedCharacterLeft">
                    {stringFieldComponent("name", "Name")}
                    {stringFieldComponent("nickname", "Short name")}
                    {intFieldComponent("actionValue", <AttackTypeComponent/>)}
                    {intFieldComponent("weaponDamage", "Weapon Damage")}

                    {intFieldComponent("defense", "Defense")}
                    {character.characterType.value === CharacterType.MOOK ? null : intFieldComponent("toughness", "Toughness")}
                    {intFieldComponent("speed", "Speed")}
                    {howManyComponent}
                    {dmExtrasLeft}

                </div>
                <div className="selectedCharacterRight">
                    {intFieldComponent("woundPoints", "Wound Points")}
                    <UpDownButton label={<FortuneTypeComponent/>} value={character.fortune.value} setValue={character.fortune.set}
                                  minValue={0} maxValue={Number.POSITIVE_INFINITY}/>
                    <UpDownButton label="Marks of death" value={character.marksOfDeath.value} setValue={character.marksOfDeath.set}
                                  minValue={0} maxValue={Number.POSITIVE_INFINITY}/>

                    <UpDownButton label="Special impairment" value={character.extraImpairment.value} setValue={character.extraImpairment.set}
                                  minValue={Number.NEGATIVE_INFINITY} maxValue={Number.POSITIVE_INFINITY}/>
                    <UpDownButton label="Defense Bonus" value={character.defenseBonus.value} setValue={character.defenseBonus.set}
                                  minValue={Number.NEGATIVE_INFINITY} maxValue={Number.POSITIVE_INFINITY}/>
                    <UpDownButton label="Toughness Bonus" value={character.toughnessBonus.value} setValue={character.toughnessBonus.set}
                                  minValue={Number.NEGATIVE_INFINITY} maxValue={Number.POSITIVE_INFINITY}/>
                    <button onClick={handleResetBonuses}>Reset Bonuses</button>
                    {dmExtrasRight}
                </div>
            </div>
            {deleter}
        </div>
    )
}