import React, {useEffect, useState} from "react";
import {
    CharacterID,
    CharacterType,
    DiceRoll,
    diceRollMaxLength,
    formatNumNamed,
    formatRolls,
    SetReactState,
    SettableCharacter
} from "./utils";
import {calcExpectedDamages, roll1d6, rollDice, sum} from "./mathUtils";
import {UpDownButton} from "./UpDownButton";
import {TooltipSpan} from "./Tooltip";

interface BonusesProps {
    fortuneDiceAvailable: number
    fortuneDice: number,
    setFortuneDice: SetReactState<number>,
    attackerAVBonus: number,
    setAttackerAVBonus: SetReactState<number>,
    attackerDamageBonus: number,
    setAttackerDamageBonus: SetReactState<number>
}

function Bonuses(props: BonusesProps) {
    const {
        fortuneDiceAvailable,
        fortuneDice,
    setFortuneDice,
    attackerAVBonus,
    setAttackerAVBonus,
    attackerDamageBonus,
    setAttackerDamageBonus} = props;
    const handleReset = () => {
        setFortuneDice(0);
        setAttackerAVBonus(0);
        setAttackerDamageBonus(0)
    }
    return (
        <div className="attackBonuses">
            <UpDownButton label="Attack fortune dice" value={fortuneDice} setValue={setFortuneDice} minValue={0} maxValue={fortuneDiceAvailable}/>
            <UpDownButton label="Attack bonus" value={attackerAVBonus} setValue={setAttackerAVBonus} minValue={Number.NEGATIVE_INFINITY} maxValue={Number.POSITIVE_INFINITY}/>
            <UpDownButton label="Damage bonus" value={attackerDamageBonus} setValue={setAttackerDamageBonus} minValue={Number.NEGATIVE_INFINITY} maxValue={Number.POSITIVE_INFINITY}/>
            <button onClick={handleReset}>Reset bonuses</button>
        </div>
    )
}

interface MultiAttackOutcome {
    plusDice: number[],
    minusDice: number[],
    fortuneDice: number[],
    nFortuneDice: number,
    plusSum: number,
    minusSum: number,
    fortuneSum: number,
    nBoxcars: number,
    actionValue: number,
    swerve: number,
    actionResult: number,
    maxDefenses: number,
    multipleTargetsPenalty: number,
    outcome: number,
    hit: boolean,
    smackdown: number
}

function calcMultiAttackOutcome(
    diceRoll: DiceRoll,
    nFortuneDice: number,
    attacker: SettableCharacter,
    avBonus: number,
    damageBonus: number,
    maxDefenses: number,
    multipleTargetsPenalty: number,
    nDiceResolved:number
): MultiAttackOutcome {
    const fortuneDice = diceRoll.rawFortunes.slice(0, nFortuneDice);
    const numBoxcars = Math.max(0,Math.min(diceRoll.plusses.length, diceRoll.minuses.length)-1);
    const plusSum = sum(diceRoll.plusses.slice(0,nDiceResolved)) - numBoxcars*6;
    const minusSum = sum(diceRoll.minuses.slice(0,nDiceResolved)) - numBoxcars*6;
    const fortuneSum = sum(fortuneDice);

    const swerve = plusSum + fortuneSum - minusSum;
    const actionValue = attacker.actionValue.value + avBonus;
    const actionResult = actionValue - multipleTargetsPenalty + swerve;
    const outcome = actionResult - maxDefenses;
    const hit = outcome >= 0;
    const smackdown =  attacker.weaponDamage.value + damageBonus + outcome;

    return {
        plusDice: diceRoll.plusses,
        minusDice: diceRoll.minuses,
        fortuneDice: fortuneDice,
        nFortuneDice: nFortuneDice,
        plusSum: plusSum,
        minusSum: minusSum,
        fortuneSum: fortuneSum,
        nBoxcars: numBoxcars,
        actionValue: attacker.actionValue.value + avBonus,
        swerve: swerve,
        actionResult: actionResult,
        maxDefenses: maxDefenses,
        multipleTargetsPenalty: multipleTargetsPenalty,
        outcome: outcome,
        hit: hit,
        smackdown: smackdown
    }
}

interface MultiAttackOutcomeProps {
    outcome: MultiAttackOutcome
    totalWeaponDamage: number
    nDiceResolved: number
}


function MultiAttackOutcomeComponent(props: MultiAttackOutcomeProps) {
    const {outcome, totalWeaponDamage, nDiceResolved} = props;
    const plusDice = formatRolls(outcome.plusDice, outcome.nBoxcars, "plusDie",nDiceResolved);
    const minusDice = formatRolls(outcome.minusDice, outcome.nBoxcars, "minusDie", nDiceResolved);
    const fortuneDice = formatRolls(outcome.fortuneDice, 0, "fortuneDie", nDiceResolved > 0 ? Number.POSITIVE_INFINITY : 0);
    const optionalFortuneCalc = " + " + formatNumNamed(outcome.fortuneSum, "fortune");
    const plusSum = <TooltipSpan text={outcome.plusSum} tooltipText={plusDice}/>
    const minusSum = <TooltipSpan text={outcome.minusSum} tooltipText={minusDice}/>
    const fortuneSum = <TooltipSpan text={outcome.fortuneSum} tooltipText={fortuneDice}/>
    const swerveCalc = (
        formatNumNamed(outcome.plusSum, "plus") +
        (outcome.nFortuneDice > 0 ? optionalFortuneCalc : "") +
        " - " + formatNumNamed(outcome.minusSum, "minus")
    )
    const swerve = <TooltipSpan text={outcome.swerve} tooltipText={swerveCalc}/>
    const optionalMultipleTargetsPenalty = " - " + formatNumNamed(outcome.multipleTargetsPenalty, "multiple targets");
    const actionResultCalc = formatNumNamed(outcome.actionValue, "AV") +
        (outcome.multipleTargetsPenalty > 0 ? optionalMultipleTargetsPenalty : "") +
        " + " + formatNumNamed(outcome.swerve, "swerve");
    const actionResult = <TooltipSpan text={outcome.actionResult} tooltipText={actionResultCalc}/>

    const outcomeCalc = (
        formatNumNamed(outcome.actionResult, "AR") +
        " - " + formatNumNamed(outcome.maxDefenses, "def")
    )
    const outcomeComponent = <TooltipSpan text={outcome.outcome} tooltipText={outcomeCalc}/>
    const hitCalc = formatNumNamed(outcome.outcome, "outcome") + " ≥ 0";
    const hit = <TooltipSpan text={outcome.hit ? "yes" : "no"} tooltipText={hitCalc}/>
    const smackdownCalc = formatNumNamed(totalWeaponDamage, "weapon") + " + " + formatNumNamed(outcome.outcome, "outcome");
    const smackdown = <TooltipSpan text={outcome.smackdown} tooltipText={smackdownCalc}/>
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Plusses</th>
                        <th className="leftBorderTH">Minuses</th>
                        {outcome.nFortuneDice > 0 ? <th className="leftBorderTH">Fortune</th> : null}
                        <th className="leftBorderTH">swerve</th>
                        <th className="leftBorderTH">action result</th>
                        <th className="leftBorderTH">outcome</th>
                        <th className="leftBorderTH">hit</th>
                        {outcome.hit ? <th className="leftBorderTH">smackdown</th> : null}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{plusSum}</td>
                        <td className="leftBorderTD">{minusSum}</td>
                        {outcome.nFortuneDice > 0 ? <td className="leftBorderTD">{fortuneSum}</td> : null}
                        <td className="leftBorderTD">{swerve}</td>
                        <td className="leftBorderTD">{actionResult}</td>
                        <td className="leftBorderTD">{outcomeComponent}</td>
                        <td className="leftBorderTD">{hit}</td>
                        {outcome.hit ? <td className="leftBorderTD">{smackdown}</td> : null}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

interface AttackDiceProps {
    outcome: MultiAttackOutcome
    nDiceResolved: number
}

function AttackDice(props: AttackDiceProps) {
    const {outcome, nDiceResolved} = props
    const plusDice = formatRolls(outcome.plusDice, outcome.nBoxcars, "plusDie",nDiceResolved);
    const minusDice = formatRolls(outcome.minusDice, outcome.nBoxcars, "minusDie", nDiceResolved);
    const fortuneDice = formatRolls(outcome.fortuneDice, 0, "fortuneDie",
        nDiceResolved > 0 ? Number.POSITIVE_INFINITY : 0, true);
    return (
        <div className="attackDice">
            <span className="plusDice">{plusDice}</span>
            <span className="fortuneDice">{fortuneDice}</span>
            <span className="minusDice">{minusDice}</span>
        </div>
    )
}

interface SingleAttackRollProps {
    diceRoll: DiceRoll, nFortuneDice: number,
    attacker: SettableCharacter,
    attackerDamageBonus: number,
    attackerAVBonus: number,
    targetList: Map<CharacterID, SettableCharacter>,
    targetIDsAndCounts: Map<CharacterID, number>,
    hasntRolledYet: boolean,
    isMooksAttacking: boolean,
    nDiceResolved: number
}

function SingleAttackRoll(props: SingleAttackRollProps) {
    const {
        diceRoll, nFortuneDice,
        attacker, attackerDamageBonus, attackerAVBonus,
        targetList, targetIDsAndCounts, hasntRolledYet,
        isMooksAttacking,
        nDiceResolved
    } = props;
    const maxDefenses = Math.max(...Array.from(targetList.values()).map((t) => t.totalDefense));
    const nTargets = sum([...targetIDsAndCounts.values()]);
    const multipleTargetsPenalty = (nTargets < 2 || isMooksAttacking) ? 0 : nTargets;
    const multiAttackOutcome = calcMultiAttackOutcome(
        diceRoll,
        nFortuneDice,
        attacker,
        attackerAVBonus,
        attackerDamageBonus,
        maxDefenses,
        multipleTargetsPenalty,
        nDiceResolved
    );
    const showYet = nDiceResolved > 0
    const firstTarget: CharacterID = Array.from(targetList.keys())[0];
    return (<div className="singleAttackRoll">
                <AttackDice outcome={multiAttackOutcome} nDiceResolved={nDiceResolved}/>
                {showYet ? <>
                    <MultiAttackOutcomeComponent
                        outcome={multiAttackOutcome}
                        totalWeaponDamage={attacker.weaponDamage.value+attackerDamageBonus}
                        nDiceResolved={nDiceResolved}
                    />
                    <DamageTable
                        targets={targetList}
                        smackdown={multiAttackOutcome.smackdown}
                        shouldRender={(targetIDsAndCounts.size > 0) && (!hasntRolledYet)}
                        hit={multiAttackOutcome.hit}
                        targetID2Counts={isMooksAttacking ? new Map().set(firstTarget,1) : targetIDsAndCounts}
                    />
                </> : null}
            </div>
    )
}

interface MooksAttackRollProps {
    diceRolls: DiceRoll[], nFortuneDice: number,
    attacker: SettableCharacter,
    attackerDamageBonus: number,
    attackerAVBonus: number,
    targetList: Map<CharacterID, SettableCharacter>,
    targetIDsAndCounts: Map<CharacterID, number>,
    hasntRolledYet: boolean,
    isMooksAttacking: boolean,
    nDiceResolved: number
}

function MooksAttackRoll(props: MooksAttackRollProps) {
    const {
        diceRolls, nFortuneDice,
        attacker, attackerDamageBonus, attackerAVBonus,
        targetList, targetIDsAndCounts, hasntRolledYet,
        isMooksAttacking,
        nDiceResolved
    } = props;
    const maxDefenses = Math.max(...Array.from(targetList.values()).map((t) => t.totalDefense));
    const nTargets = sum([...targetIDsAndCounts.values()]);
    const multipleTargetsPenalty = (nTargets < 2 || isMooksAttacking) ? 0 : nTargets;
    const multiAttackOutcomes = diceRolls.map((diceRoll) => {
        return calcMultiAttackOutcome(
        diceRoll,
        nFortuneDice,
        attacker,
        attackerAVBonus,
        attackerDamageBonus,
        maxDefenses,
        multipleTargetsPenalty,
        nDiceResolved
    )});
    const firstTarget: CharacterID = Array.from(targetList.keys())[0];
    const attackDice = [];
    const outcomeComponents = [];
    const damageTables = [];
    for (const [index, multiAttackOutcome] of multiAttackOutcomes.entries()) {
        attackDice.push(<AttackDice outcome={multiAttackOutcome} nDiceResolved={nDiceResolved} key={index}/>)
        outcomeComponents.push(<MultiAttackOutcomeComponent
                        outcome={multiAttackOutcome}
                        totalWeaponDamage={attacker.weaponDamage.value+attackerDamageBonus}
                        nDiceResolved={nDiceResolved}
                        key={index}
                    />)
        damageTables.push(<DamageTable
                        targets={targetList}
                        smackdown={multiAttackOutcome.smackdown}
                        shouldRender={(targetIDsAndCounts.size > 0) && (!hasntRolledYet)}
                        hit={multiAttackOutcome.hit}
                        targetID2Counts={isMooksAttacking ? new Map().set(firstTarget,1) : targetIDsAndCounts}
                        key={index}
                    />)
    }
    const showYet = nDiceResolved > 0

    return (<div className="singleAttackRoll">
                {attackDice}
                <hr/>
                {showYet ? <>
                    {outcomeComponents}
                    {damageTables}
                </> : null}
            </div>
    )
}

interface Props {
    attacker: SettableCharacter | null,
    targets: Map<CharacterID, SettableCharacter>,
    targetIDsAndCounts: Map<CharacterID, number>,
}

export default function AttackRoller(props: Props) {
    const {attacker, targets, targetIDsAndCounts} = props;

    const [diceRolls, setDiceRolls] = useState<DiceRoll[]>([]);

    const attackerFortune = (attacker === null ? null : attacker.fortune.value)
    const [nFortuneDice, setNFortuneDice]  = useState<number>(0);
    useEffect(() => {
        if (attackerFortune !== null && attackerFortune < nFortuneDice) {
            setNFortuneDice(attackerFortune)
        }
    }, [attackerFortune, nFortuneDice, ])

    const [nDiceResolved, setNDiceResolved]  = useState<number>(0);
    const [isRolling, setIsRolling] = useState<boolean>(false);

    useEffect(() => {
        if (isRolling) {
            const rollTimeForFirst = Math.random()*900+900; // 900 min, 1800 max
            const rollTimeForNext = Math.random()*1300+900; // 900 min, 2200 max
            const rollTime = nDiceResolved === 0 ? rollTimeForFirst : rollTimeForNext;
            const timeout = setTimeout(() => {
                const diceNeedingResolving = Math.max(...diceRolls.map(diceRollMaxLength), nFortuneDice);
                if (nDiceResolved + 1 >= diceNeedingResolving) {
                    setIsRolling(false);
                    setNDiceResolved(Number.POSITIVE_INFINITY);
                } else {
                    setNDiceResolved(nDiceResolved+1)
                }
            }, rollTime)
            return () => clearTimeout(timeout)
        }
    }, [isRolling, nDiceResolved, diceRolls, nFortuneDice])

    const [attackerAVBonus, setAttackerAVBonus]  = useState<number>(0);
    const [attackerDamageBonus, setAttackerDamageBonus] = useState<number>(0);

    const bonuses = <Bonuses
        fortuneDiceAvailable={attacker === null ? Number.POSITIVE_INFINITY : attacker.fortune.value}
        fortuneDice={nFortuneDice} setFortuneDice={setNFortuneDice}
        attackerAVBonus={attackerAVBonus} setAttackerAVBonus={setAttackerAVBonus}
        attackerDamageBonus={attackerDamageBonus} setAttackerDamageBonus={setAttackerDamageBonus}
    />
    const anObject =  attacker !== null ? (
        targetIDsAndCounts.size > 0 ? "" : "a target"
    ) : (
        targetIDsAndCounts.size > 0 ? "an attacker" : "an attacker and a target"
    )
    const pleaseSelectMessage = <>{"Please select " + anObject}</>
    if (attacker === null) {
        return (<div className="attackSectionRight">
            <div className="attackHeader">
                Attack
            </div>
            {bonuses}
            {pleaseSelectMessage}
        </div>)
    }

    const nDiceRolls = attacker.characterType.value === CharacterType.MOOK ? attacker.howMany.value : 1;
    const hasntRolledYet = diceRolls.length === 0;

    const handleAttackRoll = () => {
        const tmpDiceRolls: DiceRoll[] = [];
        for (let i = 0; i < nDiceRolls; i++) {
            const [plusses, minuses] = rollDice();
            const fortunes = Array.from({length: nFortuneDice+100}, () => roll1d6())
            tmpDiceRolls.push({
                plusses: plusses,
                minuses: minuses,
                rawFortunes: fortunes
            })
        }
        setDiceRolls(tmpDiceRolls);
        setNDiceResolved(0);
        setIsRolling(true);
    }

    // loop through attacks
    const isMooksAttacking = attacker.characterType.value === CharacterType.MOOK;
    const targetList = new Map<CharacterID, SettableCharacter>();
    for (const [cid, n] of targetIDsAndCounts.entries()) {
        if (n > 0) {
            const maybeTarget = targets.get(cid)
            if (maybeTarget !== undefined) {
                targetList.set(cid, maybeTarget)
            }
        }
    }
    const outcomeComponents = [];
    const difficulties = [];
    const pHits = [];
    const woundAvgs = [];
    if (isMooksAttacking) {
        let startRoll = 0;
        for (const [cid, character] of targetList) {
            const nTarget = targetIDsAndCounts.get(cid);
            if (nTarget !== undefined) {
                const mookDiceRolls = diceRolls.slice(startRoll, startRoll+nTarget);
                startRoll += nTarget;
                const maxDefenses = character.totalDefense;
                const toughneses = Array.from(targetList.values()).map((t) => t.totalToughness);
                difficulties.push(maxDefenses);
                const damages = calcExpectedDamages(nFortuneDice, attacker.actionValue.value,
                        maxDefenses, attacker.weaponDamage.value,
                        toughneses
                    )
                if (damages !== null) {
                    const [pHit, avgWounds] = damages;
                    pHits.push(pHit * 100)
                    woundAvgs.push(avgWounds);
                }
                if (mookDiceRolls !== undefined) {
                    // resolve the attack
                    const component = <MooksAttackRoll
                        key={cid + "mooks"}
                        diceRolls={mookDiceRolls} nFortuneDice={nFortuneDice}
                        attacker={attacker}
                        attackerDamageBonus={attackerDamageBonus}
                        attackerAVBonus={attackerAVBonus}
                        targetList={new Map().set(cid, character)} targetIDsAndCounts={targetIDsAndCounts}
                        hasntRolledYet={hasntRolledYet} isMooksAttacking={isMooksAttacking}
                        nDiceResolved={nDiceResolved}
                    />
                    outcomeComponents.push(component);
                }

            }

        }
    } else {
        // get the roll for that attack
        const diceRoll = diceRolls[0];
        const maxDefenses = Math.max(...Array.from(targetList.values()).map((t) => t.totalDefense));
        const nTargets = sum([...targetIDsAndCounts.values()]);
        const multipleTargetsPenalty = nTargets < 2 ? 0 : nTargets;
        const toughneses = Array.from(targetList.values()).map((t) => t.totalToughness);
        difficulties.push(maxDefenses+multipleTargetsPenalty);
        const damages = calcExpectedDamages(nFortuneDice, attacker.actionValue.value,
                maxDefenses+multipleTargetsPenalty, attacker.weaponDamage.value,
                toughneses
            )
        if (damages !== null) {
            const [pHit, avgWounds] = damages;
            pHits.push(pHit * 100)
            woundAvgs.push(avgWounds);
        }
        if (diceRoll !== undefined) {
            // resolve the attack
            const component = <SingleAttackRoll
                key={"PCs"}
                diceRoll={diceRoll} nFortuneDice={nFortuneDice}
                attacker={attacker}
                attackerDamageBonus={attackerDamageBonus}
                attackerAVBonus={attackerAVBonus}
                targetList={targetList} targetIDsAndCounts={targetIDsAndCounts}
                hasntRolledYet={hasntRolledYet} isMooksAttacking={isMooksAttacking}
                nDiceResolved={nDiceResolved}
            />
            outcomeComponents.push(component);
        }
    }
    const attackSummary = hasntRolledYet ? (<></>) : outcomeComponents;

    const attackSection = (
        <>
            <button onClick={handleAttackRoll}>Roll to attack</button>
            {attackSummary}
        </>
    )

    const maybeAttackSection =targetIDsAndCounts.size > 0 ? attackSection : pleaseSelectMessage
    let difficultySection;
    if (difficulties.length === 0) {
        difficultySection = null;
    }
    else if (difficulties.length === 1) {
        difficultySection = <div>{"Difficulty: " + (difficulties[0])}</div>;
    } else {
        difficultySection = <div>{"Difficulties: [" + (difficulties.join()) + "]"}</div>;
    }
    function dumbFormat(x: number, pct=false, sigDigits=0) {
        const digits = Math.pow(10, sigDigits);
        return String(Math.round(x*digits)/digits) + (pct ? "%" : "")
    }
    function dumbMultiFormat(xs: number[], pct=false) {
        return xs.map(x => dumbFormat(x, pct)).join()
    }
    let pHitSection;
    if (pHits.length === 0) {
        pHitSection = null;
    }
    else if (pHits.length === 1) {
        pHitSection = <div>{"p(hit): " + dumbFormat(pHits[0], true)} </div>;
    } else {
        pHitSection = <div>{"𝔼[hits]: " + dumbFormat(sum(pHits)/100, false, 1)}</div>;
    }
    let avgWoundsSection;
    if (woundAvgs.length === 0) {
        avgWoundsSection = null;
    }
    else if (woundAvgs.length === 1) {
        avgWoundsSection = <div>{"𝔼[wounds]: " + dumbMultiFormat(woundAvgs[0])}</div>;
    } else {
        avgWoundsSection = <div>{"𝔼[wounds]: [" + (woundAvgs.map(x => dumbMultiFormat(x)).join()) + "]"}</div>;
    }

    return (
        <div className="attackSectionRight">
            <div className="attackHeader">
                Attack
            </div>
            {targetIDsAndCounts.size === 0 ? null : difficultySection}
            {targetIDsAndCounts.size === 0 ? null : pHitSection}
            {targetIDsAndCounts.size === 0 ? null : avgWoundsSection}
            {bonuses}
            {maybeAttackSection}
        </div>
    )
}

interface DamageTableProps {
    targets: Map<CharacterID, SettableCharacter>,
    smackdown: number,
    shouldRender: boolean,
    targetID2Counts: Map<number, number>
    hit: boolean
}

function DamageTable(props : DamageTableProps) {
    const {targets, smackdown, shouldRender, targetID2Counts, hit} = props;
    if (!shouldRender) {
        return null;
    }
    const rows = Array.from(targetID2Counts).map(([id, count]) => {
        const target = targets.get(id)
        if (target === undefined) {
            console.error("target not found in damage table", id, targetID2Counts, targets)
            return null
        }
        const howManyStr = (count === 1) ? "" : (" x" + count);
        const woundInflicted = <TooltipSpan text={Math.max(0, smackdown - target.totalToughness)}
                                            tooltipText={"" + formatNumNamed(smackdown, "smackdown") +
                                            " - " + formatNumNamed(target.totalToughness, "toughness")}/>;
        const woundsStringIfHit = target.characterType.value === CharacterType.MOOK ? "DEAD"+howManyStr : woundInflicted
        return (<tr key={id}>
            <td>{target.name.value + howManyStr}</td>
            <td className="leftBorderTD">{hit ? woundsStringIfHit: "None"}</td>
        </tr>)
    })
    return (<table>
        <thead>
            <tr>
                <th>Name</th>
                <th className="leftBorderTH">Wounds inflicted</th>
            </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </table>)
}