import React from "react";
import {SetReactState} from "./utils";

interface UpDownButtonProps {
    label: string | JSX.Element,
    value: number,
    setValue: SetReactState<number> | ((_: number) => void),
    minValue: number
    maxValue: number
    showValue?: boolean
}

export function UpDownButton(props: UpDownButtonProps) {
    const {label, value, setValue, minValue, maxValue, showValue} = props;
    const handleUp = () => setValue(Math.min(maxValue,value + 1));
    const handleDown = () => setValue(Math.max(minValue, value - 1));
    const shouldShowValue: boolean = showValue === undefined ? true : showValue;
    return (
        <div>
            {label}
            <button onClick={handleDown}>&darr;</button>
            {shouldShowValue ? value: null}
            <button onClick={handleUp}>&uarr;</button>
        </div>
    );
}