import React from "react";
import AttackRoller from "./AttackRoller";
import {CharacterID, CharacterListType, handleYCharacterDeletion, SettableCharacter, YCharacterList} from "./utils";
import SelectedCharacter from "./SelectedCharacter";
import CharacterList from "./CharacterList";
import SkillRoller from "./SkillRoller";

interface Props {
    selectedAttacker: SettableCharacter | null,
    targets: Map<CharacterID, SettableCharacter>,
    selectedTargets: Map<CharacterID, number>,
    isUsingDMMode: boolean,
    deletionActive: boolean,
    yAttackers: YCharacterList,
}

export default function AttackSection(props: Props) {
    const {
        selectedAttacker, isUsingDMMode, deletionActive, yAttackers, targets, selectedTargets
    } = props
    const selectedAttackerComponent = <SelectedCharacter
          title="Selected Attacker"
          readOnly={false}
          character={selectedAttacker}
          isUsingDMMode={isUsingDMMode}
          handleDeletion={() => handleYCharacterDeletion(yAttackers, selectedAttacker)}
          deletionActive={deletionActive}
      />
    // us this really at all useful to show?
    const selectedAttackersList = <CharacterList
          characters={targets}
          characterListType={CharacterListType.TARGET}
          characterIDsToCountSelected={selectedTargets}
          readOnly={!isUsingDMMode}
          isUsingDMMode={isUsingDMMode}
          maxSelectable={0}
          isTarget={true}
          isTargetSection={true}
    />
    return (
        <div className="attackSection">
          <div className="attackSectionLeft">
            {selectedAttackerComponent}
              <hr/>
            <SkillRoller character={selectedAttacker} isUsingDMMode={isUsingDMMode}/>
          </div>
            <AttackRoller attacker={selectedAttacker} targets={targets}
                            targetIDsAndCounts={selectedTargets}
            />
        </div>
    )
}