import './names.css';
import nameDataRaw from "./ner.json"
import React, {useState} from "react";
import {getRandomSubarray} from "../fengShui/mathUtils";
import {StringTable} from "./StringTable";
import {SetReactState} from "../fengShui/utils";

const nameData = new Map<string, string[]>();

const nameTypes: string[] = [];
const nameTypeCount = new Map<String, number>();
for (const [nameType, nameList] of Object.entries(nameDataRaw)) {
    nameData.set(nameType, nameList)
    nameTypeCount.set(nameType, nameList.length);
    nameTypes.push(nameType)
}


function formatCount(nameType : string): string {
    const count = nameTypeCount.get(nameType)
    if (count === undefined) {
        return ""
    }
    if (count < 1000) {
        return String(count)
    }
    if (count < 1e6) {
        return Math.round(count/1e3) + 'k'
    }
    return Math.round(count/1e6) + "M"
}

interface NameTypeKeyProps {
    setter: SetReactState<string | null>
    resample: () => void
}
function NameTypeKey(props: NameTypeKeyProps) {
    const {setter, resample} = props;
    const typeAndDescription: [string, string][] = [
        ["PERSON", "People"],
        ["NORP", "Nationalities or religious or political groups"],
        ["FAC", "Buildings, airports, highways, bridges, etc."],
        ["ORG", "Companies, agencies, institutions, etc."],
        ['GPE', "Countries, cities, states"],
        ["LOC", "Non-GPU locations, mountain ranges, bodies of water"],
        ["PRODUCT", "Vehicles, weapons, foods, etc. (Not services)"],
        ["EVENT", "Named hurricanes, battles, wars, sports events, etc."],
        ["WORK_OF_ART", "Titles of books, songs, etc."],
        ["LAW", "Named documents made into laws"],
        ["LANGUAGE", "Any named language"]
    ]

    const rows = typeAndDescription.map(([nameType, description]) => {
        const handleClick = () => {
            resample()
            setter(nameType)
        }
        return (<tr key={nameType} onClick={handleClick}>
            <td>{nameType}</td><td>{description}</td>
        </tr>)
    })
    return (
        <table>
            <thead>
                <tr>
                    <th>
                        Entity type
                    </th>
                    <th>
                        description
                    </th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    )
}

export function NamesByType() {
    const [nNames, setNNames] = useState<number>(100);
    const [selectedNameType, setSelectedNameType] = useState<string | null>(null);
    const [unused, setUnused] = useState(false);
    const resample = () => {setUnused(!unused)}

    const nNameOptions = [1,5,20,50,100,1000].map((n) => (<option className={"nerNNamesOption"} key={n}>{n}</option> ))
    const handleNNamesChange = ({target: {value}} : {target: {value: string}}): void => {
        setNNames(parseInt(value))
    }
    const nNamesSelector = <div className="nerNNamesSelector">
        # of names:
        <select value={nNames} onChange={handleNNamesChange}>
            {nNameOptions}
        </select>
    </div>

    const handleNameTypeChange = ({target: {value}} : {target: {value: string}}): void => {
        setSelectedNameType(value)
    }
    let nameTypeOptions = nameTypes.map((nameType) => (<option className={"nerNameType"} key={nameType} value={nameType}>{nameType} ({formatCount(nameType)})</option>))
    const nameTypeKey = <NameTypeKey setter={setSelectedNameType} resample={resample}/>
    if (selectedNameType === null) {
        return (<div className="nerNames">
            {nameTypeKey}
            <hr/>
            <div className="nerSelectors">
                {nNamesSelector}
            </div>
        </div>)
    }
    const nameList = nameData.get(selectedNameType)
    if (nameList === undefined) {
        return (<div className="nerNames">
            {nameTypeKey}
            <hr/>
            <div className="nerSelectors">
                {nNamesSelector}
            </div>
        </div>)
    }
    const nameListSample = getRandomSubarray(nameList, nNames);
    let count : number | undefined | null = nameTypeCount.get(selectedNameType)
    let countElement;
    if (count === undefined) {
        countElement = null
    } else {
        countElement = count + " names available"
    }
    return (<div className="nerNames">
            {nameTypeKey}
            <hr/>
            <div className="nerSelectors">
                {nNamesSelector}
                <button onClick={resample}>Resample</button>
            </div>
            <hr/>
            <div className="nNamesAvailable">{selectedNameType}: {countElement}</div>
            <StringTable entries={nameListSample} maxWidth={5}/>
        </div>)

}