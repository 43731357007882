
interface Props {
    handleUserNameChange: (_:{target: {value: string}}) => void,
    userName: string
}

export default function UsernameInput(props: Props) {
    return (
        <div>
            <label htmlFor="username-input">
                <div>{"Your Character Name: "}</div>
                <input type="text" id='username-input' name='username-input'
                    onChange={props.handleUserNameChange} value={props.userName}
                />
            </label>
        </div>
    );
}
