import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './fengShui/App';
import reportWebVitals from './fengShui/reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {Nav} from "./Nav";
import {NamesByType} from "./names/NamesByType";
import {NamesByUsage} from "./names/NamesByUsage";

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Nav/>
          <hr/>
          <Switch>
              <Route path="/fengshui">
                  <App/>
              </Route>
              <Route path="/names_by_type">
                  <NamesByType/>
              </Route>
              <Route path="/names_by_usage">
                  <NamesByUsage/>
              </Route>
              <Route path="/">
                  <div>
                      Combat tracker and name generator
                  </div>
              </Route>
          </Switch>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
