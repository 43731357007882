import React, {useEffect} from "react";
import {
    CharacterID,
    CharacterType,
    newMapWith,
    newMapWithFewer,
    newMapWithout,
    Settable,
    SettableCharacter
} from "./utils";
import {UpDownButton} from "./UpDownButton";
import {sum} from "./mathUtils";

interface Props {
    character: SettableCharacter,
    selectedCharacterIDsAndCounts: Map<CharacterID, number>,
    setSelectedCharacterIDsAndCounts?: React.Dispatch<React.SetStateAction<Map<CharacterID, number>>>,
    isSelected: boolean,
    isTheSoleSelected: boolean,
    countSelected: number,
    isUsingDMMode: boolean,
    maxSelectable: number,
    isTargetSection: boolean,
    potentialAttacker?: SettableCharacter | null
}

export default function MinimalCharacterSelector(props: Props) {
    const {
        character, setSelectedCharacterIDsAndCounts, isSelected, isTheSoleSelected,
        countSelected,
        selectedCharacterIDsAndCounts,
        isUsingDMMode,
        maxSelectable,
        isTargetSection,
        potentialAttacker
    } = props;

    const selectedID2Count = new Map<number, number>();
    for (const [id, count] of selectedCharacterIDsAndCounts) {
        selectedID2Count.set(id, count)
    }

    // handle DM toggles for visible / dead
    const handleToggleDead = () => {
        character.dead.set(!character.dead.value)
    }

    const handleToggleVisible = () => {
        character.visible.set(!character.visible.value);
    }


    // Set up style
    // ... based on impairment / boss WP
    let wpClass = ''
    const selectedClass = (isSelected && !isTargetSection) ? " selectedCharacterRow "  : "";
    if (character.woundPoints.value >= 25) {
        wpClass = " oneImpairmentWP "
    }
    if (character.woundPoints.value >= 30) {
        wpClass = " twoImpairmentWP "
    }
    if (character.woundPoints.value >= 35) {
        wpClass = " ffDownWP "
    }
    if (character.woundPoints.value >= 50) {
        wpClass = " bossDownWP "
    }

    // extra components for DM
    const deadCheckBox = <input
        type="checkbox" onChange={handleToggleDead} checked={character.dead.value}
    />
    const invisibleCheckBox = <input
        type="checkbox" onChange={handleToggleVisible} checked={!character.visible.value}
    />

    const setCountSelected = (n: number) => {
        if (setSelectedCharacterIDsAndCounts === undefined) {
            return;
        }
        const thisCharacterID = character.characterID;
        let otherIDsAndCounts = newMapWithout(selectedCharacterIDsAndCounts, thisCharacterID)
        const currentlySelected = sum([...otherIDsAndCounts.values()]);
        if (currentlySelected + n > maxSelectable) {
            const nToRemove = currentlySelected + n - maxSelectable;
            otherIDsAndCounts = newMapWithFewer(otherIDsAndCounts, nToRemove);
        }
        if (n === 0) {
            setSelectedCharacterIDsAndCounts(otherIDsAndCounts);
        } else {
            const newMap = newMapWith(otherIDsAndCounts, thisCharacterID, n);
            setSelectedCharacterIDsAndCounts(newMap);
        }
    }
    const handleToggleSelected = () => {
        if (setSelectedCharacterIDsAndCounts === undefined) {
            return;
        }
        const thisCharacterID = character.characterID;
        if (!isSelected) {
            // then add this
            setCountSelected(1);
        } else {
            setSelectedCharacterIDsAndCounts(
                newMapWithout(selectedCharacterIDsAndCounts, thisCharacterID)
            );
        }
    }


    useEffect(() => {
        if (!isTargetSection && setSelectedCharacterIDsAndCounts !== undefined) {
            const currentlySelected = sum([...selectedCharacterIDsAndCounts.values()]);
            if (currentlySelected > maxSelectable) {
                const nToRemove = currentlySelected - maxSelectable;
                const remainingIDsAndCounts = newMapWithFewer(selectedCharacterIDsAndCounts, nToRemove);
                setSelectedCharacterIDsAndCounts(remainingIDsAndCounts);
            }
        }
    }, [maxSelectable, selectedCharacterIDsAndCounts, setSelectedCharacterIDsAndCounts, isTargetSection])

    const handleClickSelected = () => {
        if (setSelectedCharacterIDsAndCounts === undefined) {
            return;
        }
        const cid = character.characterID;
        setSelectedCharacterIDsAndCounts(new Map().set(cid, character.howMany.value))
    }
    const selectedToggleCol = <input
        type="checkbox"
        checked={isSelected}
        onChange={handleToggleSelected}
    />
    const isMooksAttacking = potentialAttacker !== undefined && potentialAttacker !== null && potentialAttacker.characterType.value === CharacterType.MOOK;
    const selectedUpDown = <UpDownButton label="" value={countSelected} setValue={setCountSelected} minValue={0} maxValue={isMooksAttacking ? maxSelectable : character.howMany.value}/>
    const selectedExtras = (
        <>
            {maxSelectable > 1 ? <td className="tdSelectMany">{(character.howMany.value > 1 || isMooksAttacking ? selectedUpDown : selectedToggleCol)}</td> : null}
        </>
    )
    const dmExtras = (isUsingDMMode && (maxSelectable > 0)) ? [(<td key="dead">{deadCheckBox}</td>), (<td key="visible">{invisibleCheckBox}</td>)] : []
    const selectedCount = selectedID2Count.get(character.characterID);
    const countOrCountTargeted = isTargetSection ? selectedCount : character.howMany.value
    const howManyStr = (countOrCountTargeted === 1) ? "" : ("x" + countOrCountTargeted);
    // render
    if (!character.visible && !isUsingDMMode) {
        return null;
    }
    const impairableClass = "leftBorderTD " + (character.dead.value ? "" : wpClass) + selectedClass;
    const tdClass = "leftBorderTD " + selectedClass;
    let deadOrSelectedClass = character.dead.value ? " selectedCharacterDead " : selectedClass;
    let invisibleClass = character.visible.value ? "" : " invisibleCharacterRow "
    let trClass = deadOrSelectedClass + " " + invisibleClass
    function hideForPCs(element: JSX.Element): JSX.Element | null {
        if (character.characterType.value !== CharacterType.PC && !isUsingDMMode) {
            return null
        }
        return element
    }
    function replaceClassIfDoesntParse(priorClasses: string, settables: Settable<any, any>[]) {
        for (const settable of settables) {
            if (!settable.parses) {
                return "invalidCharacterField"
            }
        }
        return priorClasses;
    }
    return (
        <tr className={trClass}>
            {selectedExtras}
            <td onClick={handleClickSelected} className={replaceClassIfDoesntParse("", [character.name, character.nickname])}>
                {character.name.value} {howManyStr}
            </td>
            {hideForPCs(<td className={replaceClassIfDoesntParse(impairableClass, [character.actionValue, character.woundPoints, character.extraImpairment])} onClick={handleClickSelected}>
                {character.totalAV}
            </td>)}
            {hideForPCs(<td className={replaceClassIfDoesntParse(tdClass, [character.weaponDamage])} onClick={handleClickSelected}>
                {character.weaponDamage.value}
            </td>)}
            <td className={replaceClassIfDoesntParse(impairableClass, [character.defenseBonus, character.defense, character.woundPoints, character.extraImpairment])} onClick={handleClickSelected}>
                {character.totalDefense}
            </td>
            {hideForPCs(<td className={replaceClassIfDoesntParse(tdClass, [character.toughness, character.toughnessBonus])} onClick={handleClickSelected}>
                {character.characterType.value === CharacterType.MOOK ? "" : character.totalToughness}</td>)}
            {hideForPCs(<td className={replaceClassIfDoesntParse(tdClass, [character.speed])} onClick={handleClickSelected}>{character.speed.value}
            </td>)}
            <td className={replaceClassIfDoesntParse(impairableClass, [character.woundPoints])} onClick={handleClickSelected}>
                {character.woundPoints.value}
            </td>
            <td className={replaceClassIfDoesntParse(impairableClass, [character.woundPoints, character.extraImpairment])} onClick={handleClickSelected}>
                {character.impairment}
            </td>
            {dmExtras}
        </tr>
    )

}